import { Cancel as CancelIcon, FactCheck as FactCheckIcon, Save as SaveIcon } from "@mui/icons-material";
import { Button, ButtonGroup, Container, LinearProgress, Paper, Typography } from "@mui/material";
import { TextField } from "formik-mui";
import { Field, Form, Formik } from "formik";
import React from "react";
import { FormProps, SetPageTitle } from "@arcadia/core.base.ui";
import GearsManifest from "../../DataServices/Modals/GearsManifest";

function SaveGearsManifest(values: GearsManifest, setSubmitting: any) {
  console.log(values);
  setTimeout(() => {
    setSubmitting(false);
  }, 500);
}

const GearsManifestForm = (props: FormProps) => {
  const pageTitle = `${props.Action} Users Group`;
  const _GearsManifest = new GearsManifest();
  SetPageTitle(pageTitle);

  return (
    <Container maxWidth="md">
      <Paper sx={{ padding: 5 }}>
        <Typography variant="h4">{pageTitle}</Typography>
        <Formik initialValues={_GearsManifest} onSubmit={(values, { setSubmitting }) => SaveGearsManifest(values, setSubmitting)}>
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Field component={TextField} autoFocus margin="normal" name="ArabicName" label="Name (Arabic)" type="text" fullWidth required variant="filled" />
              <Field component={TextField} margin="normal" name="EnglishName" label="Name (English)" type="text" fullWidth required variant="filled" />
              <Field component={TextField} margin="normal" name="Remarks" label="Remarks" type="text" multiline maxRows={4} fullWidth variant="filled" />
              {isSubmitting && <LinearProgress />}
              <ButtonGroup variant="contained" disabled={isSubmitting}>
                <Button startIcon={<SaveIcon />} onClick={submitForm}>
                  Save
                </Button>
                <Button startIcon={<CancelIcon />}>Cancel</Button>
                <Button startIcon={<FactCheckIcon />}>Validate</Button>
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default GearsManifestForm;
