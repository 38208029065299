import { Typography } from "@mui/material";
import React from "react";

const Dependencies = () => {
  return (
    <Typography variant="h5" sx={{ color: "#00ff00" }}>
      TODO -- Dependencies
    </Typography>
  );
};

export default Dependencies;
