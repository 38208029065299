import { Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import SettingDefinition from "../../../../DataServices/Modals/SettingDefinition";
import { Cancel as CancelIcon, Delete as DeleteIcon, Save as SaveIcon } from "@mui/icons-material";

interface SettingDefinitionProps {
  SettingDefinition: SettingDefinition;
}

const SettingView = (props: SettingDefinitionProps) => {
  return (
    <Box
      sx={{
        minWidth: "350px",
        alignItems: "left",
      }}
    >
      <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.grey[800], borderRadius: 4, paddingLeft: 2, textAlign: "center" }}>
        {props.SettingDefinition.Name}
      </Typography>
      <Formik initialValues={props.SettingDefinition} onSubmit={(values, { setSubmitting }) => {}}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field component={TextField} autoFocus sx={{ marginTop: 1.5, marginLeft: 1, width: 500 }} name="Name" label="Name" type="text" fullWidth required variant="filled" id="txtName" />

            <InputLabel>
              <Field component={Checkbox} margin="normal" name="UserControlled" type="checkbox" required variant="filled" id="chkUserControlled" />
              User Controlled
            </InputLabel>

            <FormControl component="fieldset" sx={{ margin: 1 }}>
              <FormLabel component="legend">Scope</FormLabel>
              <RadioGroup row={true} name="Scope" value={props.SettingDefinition.Scope}>
                <FormControlLabel value="System" control={<Radio />} label="System" />
                <FormControlLabel value="User" control={<Radio />} label="User" />
              </RadioGroup>
            </FormControl>
            <Box>
              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcTitleLabel">Title</InputLabel>
                <Select labelId="slcTitleLabel" id="slcTitle" label="Title" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcDescriptionLabel">Description</InputLabel>
                <Select labelId="slcDescriptionLabel" id="slcDescription" label="Description" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
              <InputLabel id="slcIconLabel">Icon</InputLabel>
              <Select labelId="slcIconLabel" id="slcIcon" label="Icon" value="Core">
                <MenuItem value="Arcadia">Arcadia</MenuItem>
                <MenuItem value="Core">Core</MenuItem>
                <MenuItem value="Providers">Providers</MenuItem>
              </Select>
            </FormControl>
            <br />
            <Field component={TextField} sx={{ marginTop: 1.5, marginLeft: 1, width: 500 }} name="DefaultValue" label="DefaultValue" type="text" fullWidth variant="filled" id="txtDefaultValue" />
            <Box>
              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcSettingTypeLabel">SettingType</InputLabel>
                <Select labelId="slcSettingTypeLabel" id="slcSettingType" label="SettingType" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcDbTypeLabel">Db Type</InputLabel>
                <Select labelId="slcDbTypeLabel" id="slcDbType" label="DbType" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <FormControl component="fieldset" sx={{ margin: 1 }}>
              <FormLabel component="legend">Relation</FormLabel>
              <RadioGroup row={true} name="Relation" value={props.SettingDefinition.Relation}>
                <FormControlLabel value="None" control={<Radio />} label="None" />
                <FormControlLabel value="DataUnit" control={<Radio />} label="DataUnit" />
                <FormControlLabel value="Definition" control={<Radio />} label="Definition" />
                <FormControlLabel value="Enumerator" control={<Radio />} label="Enumerator" />
              </RadioGroup>
            </FormControl>
            <br />
            <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
              <InputLabel id="slcRelationIDLabel">Relation ID</InputLabel>
              <Select labelId="slcRelationIDLabel" id="slcRelationID" label="RelationID" value="Core">
                <MenuItem value="Arcadia">Arcadia</MenuItem>
                <MenuItem value="Core">Core</MenuItem>
                <MenuItem value="Providers">Providers</MenuItem>
              </Select>
            </FormControl>
            <br />
            <ButtonGroup style={{ margin: 5 }} variant="contained">
              <Button color="primary" startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button color="secondary" startIcon={<CancelIcon />}>
                Cancel
              </Button>
              <Button color="error" startIcon={<DeleteIcon />}>
                Delete
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SettingView;
