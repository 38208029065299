import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { toast } from "react-toastify";
import GearsManifest from "../../Modals/GearsManifest";
import { ApiCallBegan } from "../Api/ApiActions";
import { AppDispatch, RootState } from "../DataStore";

interface InitialState {
  GearManifests: GearsManifest[];
  IsLoading: boolean;
  LastFetch?: number | null;
}

export const LoadGearsManifests = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { LastFetch } = getState().GearsManifests;

  const diffInMinuts = moment().diff(moment(LastFetch), "minutes");

  if (diffInMinuts < 10) return;

  dispatch(
    ApiCallBegan({
      url: "CodeGeneration/GearManifests",
      onSuccess: GearsManifestsSlice.actions.GearsManifestsLoaded.type,
      onStart: GearsManifestsRequested.type,
      onError: GearsManifestsLoadError.type,
    })
  );
};

export const addGearsManifest = (GearsManifest: GearsManifest) =>
  ApiCallBegan({
    url: "/GearsManifests",
    method: "post",
    data: GearsManifest,
    onSuccess: GearsManifestAdded.type,
  });

const GearsManifestsSlice = createSlice({
  name: "GearsManifests",
  initialState: {
    GearManifests: [],
    IsLoading: false,
    LastFetch: null,
    LastCall: null,
  } as InitialState,
  reducers: {
    GearsManifestsRequested: (GearsManifests, action) => {
      GearsManifests.IsLoading = true;
    },
    GearsManifestAdded(GearsManifests, action) {
      GearsManifests.GearManifests.push(action.payload.data);
    },
    GearsManifestsLoaded(state, action) {
      state.GearManifests = action.payload.data;
      state.IsLoading = false;
      state.LastFetch = Date.now();
    },
    GearsManifestsLoadError(state, payload) {
      state.GearManifests = [];
      state.IsLoading = false;
      state.LastFetch = null;
      toast.error(`ops error: ${payload}`);
    },
  },
});

const { GearsManifestAdded, GearsManifestsRequested, GearsManifestsLoadError } = GearsManifestsSlice.actions;
export default GearsManifestsSlice.reducer;
