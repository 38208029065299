import { ListView, ListViewGroup, ListViewItem, SetPageTitle } from "@arcadia/core.base.ui";
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Launch as LaunchIcon, Refresh as RefreshIcon } from "@mui/icons-material";
import { Button, ButtonGroup, Typography } from "@mui/material";
import React from "react";

const ValidationPresets = () => {
  SetPageTitle("Validation Presets");

  const [SelectedItem, SetSelectedItem] = React.useState<ListViewItem | undefined>(undefined);

  function ItemClicked(_item: ListViewItem) {
    SetSelectedItem(_item);
  }

  const groups: ListViewGroup[] = [
    new ListViewGroup("Validations", "", [
      new ListViewItem("1", "RequiredField", "Core.Item"),
      new ListViewItem("2", "EmailValidation", "Core.Item"),
      new ListViewItem("3", "MobileValidation", "Core.Item"),
      new ListViewItem("4", "UniqueNumber", "Core.Item"),
      new ListViewItem("5", "UniqueName", "Core.Item"),
      new ListViewItem("6", "RequiredNumber", "Core.Item"),
      new ListViewItem("7", "FiscalPeriodLimit", "Core.Item"),
    ]),
  ];

  return (
    <>
      <Typography variant="h3">Validation Presets{SelectedItem && " - " + SelectedItem?.Phrase}</Typography>
      <ButtonGroup variant="contained" sx={{ marginTop: 2 }}>
        <Button startIcon={<AddIcon />}>Add</Button>
        {SelectedItem && (
          <>
            <Button startIcon={<EditIcon />}>Edit</Button>
            <Button startIcon={<DeleteIcon />}>Delete</Button>
            <Button startIcon={<LaunchIcon />}>View</Button>
          </>
        )}
        <Button startIcon={<RefreshIcon />}>Refresh</Button>
      </ButtonGroup>
      <ListView Items={groups} onItemClick={ItemClicked} />
    </>
  );
};

export default ValidationPresets;
