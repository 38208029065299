import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { ColoredScrollbars } from "@arcadia/core.base.ui";
import DataUnitProperties from "./DataUnitPartials/DataUnitProperties";
import Dependencies from "./DataUnitPartials/Dependencies";
import Fields from "./DataUnitPartials/Fields";
import FieldsEditor from "./DataUnitPartials/FieldsEditor";
import Relations from "./DataUnitPartials/Relations";
import DataUnit from "../../../../DataServices/Modals/DataUnit";

export interface DataUnitProps {
  DataUnit: DataUnit;
}

const DataUnitView = ({ DataUnit }: DataUnitProps) => {
  const [selectedTab, setSelectedTab] = React.useState("Properties");

  function OnTabChanged(event: React.SyntheticEvent, newValue: string) {
    setSelectedTab(newValue);
  }

  return (
    <>
      <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.grey[800], borderRadius: 4, paddingLeft: 2, textAlign: "center" }}>
        {DataUnit.Name}
      </Typography>

      <Box sx={{ height: "calc(100% - 32px)" }}>
        <ColoredScrollbars>
          <TabContext value={selectedTab}>
            <Tabs onChange={OnTabChanged} value={selectedTab} indicatorColor="primary" variant="scrollable" scrollButtons="auto">
              <Tab value="Properties" label="Properties" />
              <Tab value="Fields" label="Fields" />
              <Tab value="FieldsEditor" label="Fields Editor" />
              <Tab value="Relations" label="Relations" />
              <Tab value="Dependencies" label="Dependencies" />
            </Tabs>
            <TabPanel value="Properties" sx={{ height: "calc(100% - 48px)", padding: 1 }}>
              {DataUnit && <DataUnitProperties DataUnit={DataUnit} />}
            </TabPanel>
            <TabPanel value="Fields" sx={{ height: "calc(100% - 48px)", padding: 0 }}>
              <Fields FieldsGroups={DataUnit.FieldsGroups} />
            </TabPanel>
            <TabPanel value="FieldsEditor">
              <FieldsEditor FieldsGroups={DataUnit.FieldsGroups} />
            </TabPanel>
            <TabPanel value="Relations">
              <Relations />
            </TabPanel>
            <TabPanel value="Dependencies">
              <Dependencies />
            </TabPanel>
          </TabContext>
        </ColoredScrollbars>
      </Box>
    </>
  );
};

export default DataUnitView;
