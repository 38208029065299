import { ListView, ListViewGroup, ListViewItem, SetPageTitle } from "@arcadia/core.base.ui";
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Launch as LaunchIcon, Refresh as RefreshIcon } from "@mui/icons-material";
import { Button, ButtonGroup, Typography } from "@mui/material";
import React from 'react'

const ValidationDefinitions = () => {
  SetPageTitle("Validation Definitions");

  const [SelectedItem, SetSelectedItem] = React.useState<ListViewItem | undefined>(undefined);

  function ItemClicked(_item: ListViewItem) {
    SetSelectedItem(_item);
  }

  const groups: ListViewGroup[] = [
    new ListViewGroup("Validations", "", [
      new ListViewItem("1", "Required", "Core.Item"),
      new ListViewItem("2", "Card", "Core.Item"),
      new ListViewItem("3", "Color", "Core.Item"),
      new ListViewItem("4", "Compare", "Core.Item"),
      new ListViewItem("5", "DateRange", "Core.Item"),
      new ListViewItem("6", "Ean", "Core.Item"),
      new ListViewItem("7", "Email", "Core.Item"),
      new ListViewItem("8", "IBAN", "Core.Item"),
      new ListViewItem("9", "IPAddress", "Core.Item"),
      new ListViewItem("10", "Phone", "Core.Item"),
      new ListViewItem("11", "NumberRange", "Core.Item"),
      new ListViewItem("12", "NationalID", "Core.Item"),
      new ListViewItem("13", "ISBN", "Core.Item"),
      new ListViewItem("14", "ZipCode", "Core.Item"),
      new ListViewItem("15", "VAT", "Core.Item"),
    ]),
  ];

  return (
    <>
      <Typography variant="h3">Validation Definitions{SelectedItem && " - " + SelectedItem?.Phrase}</Typography>
      <ButtonGroup variant="contained" sx={{ marginTop: 2 }}>
        <Button startIcon={<AddIcon />}>Add</Button>
        {SelectedItem && (
          <>
            <Button startIcon={<EditIcon />}>Edit</Button>
            <Button startIcon={<DeleteIcon />}>Delete</Button>
            <Button startIcon={<LaunchIcon />}>View</Button>
          </>
        )}
        <Button startIcon={<RefreshIcon />}>Refresh</Button>
      </ButtonGroup>
      <ListView Items={groups} onItemClick={ItemClicked} />
    </>
  );
}

export default ValidationDefinitions