import { Card, CardActionArea, CardContent, CardMedia, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { SetPageTitle } from "@arcadia/core.base.ui";

const Home = () => {
  SetPageTitle("Home");

  var _color = "transparent";
  if (process.env.REACT_APP_ENV === "staging") _color = "#DD2C00";
  else if (process.env.REACT_APP_ENV === "local") _color = "#827717";
  return (
    <>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2} className="homeContainer">
        <Card sx={{ maxWidth: 345, textDecoration: "none" }} component={Link} to="/Arcadia/GearsManifests">
          <CardActionArea>
            <CardMedia component="img" height="140" image="assets/logo.png" alt="GearsManifests" />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                GearsManifests
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Create, update & manage GearsManifests from here.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Stack>
      <Typography sx={{ margin: 2, backgroundColor: _color }} align="center">
        {process.env.REACT_APP_ENV} -{process.env.REACT_APP_VERSION} - All Rights Reserved &copy; {new Date().getFullYear()}
      </Typography>
    </>
  );
};

export default Home;
