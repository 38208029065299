import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Checkbox, TextField } from "formik-mui";
import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/theme-gruvbox";
import "ace-builds/src-noconflict/ext-language_tools";
import DataUnit from "../../../../../DataServices/Modals/DataUnit";
import { Cancel as CancelIcon, Delete as DeleteIcon, Save as SaveIcon } from "@mui/icons-material";
import { DeleteDataUnit } from "../../../../../DataServices/DataApi/Slices/DataUnitsSlice";
import { useAppDispatch } from "../../../../../DataServices/DataApi/DataStore";

interface DUPProps {
  DataUnit: DataUnit;
}

const DataUnitProperties = ({ DataUnit }: DUPProps) => {
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const Delete_Click = () => {
    setOpenDeleteDialog(true);
  };
  const dispatch = useAppDispatch();

  const DoDelete_Click = () => {
    setOpenDeleteDialog(false);
    dispatch(DeleteDataUnit(DataUnit.ID));
  };

  return (
    <Box
      sx={{
        minWidth: "350px",
        alignItems: "left",
      }}
    >
      <Dialog open={openDeleteDialog} keepMounted onClose={DoDelete_Click} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Delete DataUnit confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">You're going to delete the DataUnit and it's related things, are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>No</Button>
          <Button onClick={DoDelete_Click}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Formik initialValues={DataUnit} onSubmit={(values, { setSubmitting }) => {}}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field component={TextField} autoFocus margin="normal" name="SubNamespace" label="Sub Namespace" type="text" required variant="filled" id="txtSubNamespace" />
            <Box>
              <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
                <InputLabel id="slcPluralPhraseLabel">Plural Phrase</InputLabel>
                <Select labelId="slcPluralPhraseLabel" id="slcPluralPhrase" label="PluralPhrase" value="Core" style={{ margin: 3 }}>
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
                <InputLabel id="slcSingularNameLabel">Singular Name</InputLabel>
                <Select labelId="slcSingularNameLabel" id="slcSingularName" label="SingularName" value="Core" style={{ margin: 3 }}>
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
                <InputLabel id="slcIconLabel">Icon</InputLabel>
                <Select labelId="slcIconLabel" id="slcIcon" label="Icon" value="Core" style={{ margin: 3 }}>
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <InputLabel sx={{ width: 170, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="IsSubDataUnit" type="checkbox" required variant="filled" id="chkIsSubDataUnit" />
              Is Sub Data Unit
            </InputLabel>

            <InputLabel sx={{ width: 180, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="IsHierarchyTable" type="checkbox" required variant="filled" id="chkIsHierarchyTable" />
              Is Hierarchy Table
            </InputLabel>

            <InputLabel sx={{ width: 120, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="Named" type="checkbox" required variant="filled" id="chkNamed" />
              Name
            </InputLabel>

            <InputLabel sx={{ width: 120, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="Togglable" type="checkbox" required variant="filled" id="chkTogglable" />
              Togglable
            </InputLabel>

            <InputLabel sx={{ width: 120, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="Numbered" type="checkbox" required variant="filled" id="chkNumbered" />
              Numbered
            </InputLabel>

            <InputLabel sx={{ width: 150, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="IsBrowsable" type="checkbox" required variant="filled" id="chkIsBrowsable" />
              Is Browsable
            </InputLabel>

            <InputLabel sx={{ width: 180, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="IsUserControlled" type="checkbox" required variant="filled" id="chkIsUserCon" />
              Is User Controlled
            </InputLabel>

            <InputLabel sx={{ width: 120, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="IsDated" type="checkbox" required variant="filled" id="chkIsDated" />
              Is Dated
            </InputLabel>

            <InputLabel sx={{ width: 180, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="IsLinkedDU" type="checkbox" required variant="filled" id="IsLinkedDU" />
              Is Linked Data Unit
            </InputLabel>

            <InputLabel sx={{ width: 140, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="IsDocument" type="checkbox" required variant="filled" id="chkIsDocument" />
              Is Document
            </InputLabel>

            <InputLabel sx={{ width: 120, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="CanAdd" type="checkbox" required variant="filled" id="chkCanAdd" />
              Can Add
            </InputLabel>

            <InputLabel sx={{ width: 120, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="CanDelete" type="checkbox" required variant="filled" id="chkCanDelete" />
              Can Delete
            </InputLabel>

            <InputLabel sx={{ width: 120, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="CanEdit" type="checkbox" required variant="filled" id="chkCanEdit" />
              Can Edit
            </InputLabel>

            <InputLabel sx={{ width: 120, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="CanView" type="checkbox" required variant="filled" id="chkCanView" />
              Can View
            </InputLabel>

            <AceEditor mode="csharp" theme="gruvbox" name="EntityToString" fontSize={15} value={DataUnit.EntityToString} showPrintMargin={false} editorProps={{ $blockScrolling: true, enableBasicAutocompletion: true, enableLiveAutocompletion: true }} />

            <ButtonGroup style={{ margin: 5 }} variant="contained">
              <Button color="primary" startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button color="secondary" startIcon={<CancelIcon />}>
                Cancel
              </Button>
              <Button color="error" startIcon={<DeleteIcon />} onClick={Delete_Click}>
                Delete
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DataUnitProperties;
