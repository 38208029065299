import { Add as AddIcon, FormatListBulleted as FormatListBulletedIcon, Refresh as RefreshIcon } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import React from "react";
import ListsDefinitionView from "./Partials/ListsDefinitionView";
import { ColoredScrollbars, RequireAuth } from "@arcadia/core.base.ui";
import { useAppDispatch, useAppSelector } from "../../DataServices/DataApi/DataStore";
import { LoadListsDefinitions } from "../../DataServices/DataApi/Slices/ListsDefinitionsSlice";
import ListsDefinition from "../../DataServices/Modals/ListsDefinition";
import Backdrop from "../../Backdrop";

function GetSelectedPartial(selectedPartial: string, selectedItemId: string, listsDefinitions: ListsDefinition[]): JSX.Element {
  if (selectedItemId === "") return <>&lt;&lt;&lt;&lt; Please select an item to display..</>;
  switch (selectedPartial) {
    case "ListsDefinition":
      const _listsDefinition = listsDefinitions.filter((__listsDefinition) => __listsDefinition.ID === selectedItemId);
      if (_listsDefinition.length === 0) return <>Ops, Command Definition not found in props!</>;
      return <ListsDefinitionView ListsDefinition={_listsDefinition[0]} />;
    default:
      throw new Error(`Invalid selectedPartial: ${selectedPartial}`);
  }
}

const ListsDefinitions = () => {
  const [selectedPartial, setSelectedPartial] = React.useState("ListsDefinition");
  const [selectedItemId, SetSelectedItemId] = React.useState("");

  const _listsDefinitions = useAppSelector((state) => state.ListsDefinitions);

  const dispatch = useAppDispatch();
  function LoadData() {
    dispatch(LoadListsDefinitions());
  }
  React.useEffect(() => {
    LoadData();
  }, [1]);

  function OnItemClicked(id: string) {
    SetSelectedItemId(id);
    setSelectedPartial("ListsDefinition");
  }

  return (
    <RequireAuth>
      <>
        <Backdrop open={_listsDefinitions.IsLoading} />
        <Grid
          justifyContent="stretch"
          alignContent="stretch"
          container
          spacing={0.5}
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            borderRadius: 2,
            height: `100%`,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                height: 40,
                backgroundColor: (theme) => theme.palette.background.default,
                padding: 0.5,
                margin: 0.5,
                borderRadius: 3,
                alignItems: "center",
              }}
            >
              <ButtonGroup size="small" variant="contained" sx={{ marginLeft: 1 }}>
                <Button>
                  <AddIcon /> New List Definition
                </Button>
                <Button>
                  <RefreshIcon />
                </Button>
              </ButtonGroup>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2} sx={{ height: "calc(100% - 64px)" }}>
            <ColoredScrollbars>
              <List>
                {_listsDefinitions.ListDefinitions.map((_listsDefinition: ListsDefinition) => (
                  <ListItem key={_listsDefinition.ID} disablePadding>
                    <ListItemButton onClick={() => OnItemClicked(_listsDefinition.ID)}>
                      <ListItemIcon>
                        <FormatListBulletedIcon />
                      </ListItemIcon>
                      <ListItemText primary={`${_listsDefinition.DefinitionCode} - ${_listsDefinition.EnglishName}`} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </ColoredScrollbars>
          </Grid>

          <Grid
            item
            xs={0}
            sm={6}
            md={9}
            lg={10}
            xl={10}
            sx={{
              display: {
                xs: "none",
                md: "initial",
              },
            }}
          >
            <Box sx={{ height: "100%" }}>
              <ColoredScrollbars>{GetSelectedPartial(selectedPartial, selectedItemId, _listsDefinitions.ListDefinitions)}</ColoredScrollbars>
            </Box>
          </Grid>
        </Grid>
      </>
    </RequireAuth>
  );
};

export default ListsDefinitions;
