import { Alert, Box, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import DataGrid, { Column } from "react-data-grid";
import React from "react";
import { ColorPicker } from "@arcadia/core.base.ui";
import ListsDefinitionDetail from "../../../DataServices/Modals/ListsDefinitionDetail";
import ListsDefinition from "../../../DataServices/Modals/ListsDefinition";
import { Cancel as CancelIcon, Delete as DeleteIcon, Save as SaveIcon } from "@mui/icons-material";

interface ListsDefinitionProps {
  ListsDefinition: ListsDefinition;
}

const ListsDefinitionView = ({ ListsDefinition }: ListsDefinitionProps) => {
  function GetColumns(): readonly Column<ListsDefinitionDetail>[] {
    return [
      { key: "ArabicName", name: "ArabicName", width: 250 },
      { key: "EnglishName", name: "EnglishName", width: 250 },
      { key: "ParentID", name: "ParentID", width: 100 },
    ];
  }

  const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<string>>(() => new Set());
  function OnRowClick(row: ListsDefinitionDetail, column: Column<ListsDefinitionDetail, unknown>) {
    setSelectedRows(new Set<string>([row.ID]));
  }
  function rowKeyGetter(row: ListsDefinitionDetail) {
    return row.ID;
  }

  return (
    <Box
      sx={{
        minWidth: "350px",
        alignItems: "left",
      }}
    >
      <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.grey[800], borderRadius: 4, paddingLeft: 2, textAlign: "center" }}>
        {ListsDefinition.EnglishName}
      </Typography>

      {ListsDefinition.NeedsExport && (
        <Alert variant="filled" severity="warning" sx={{ margin: 2 }}>
          Warning: This lists definition needs re-export — <strong>check it out!</strong>
        </Alert>
      )}

      <Formik initialValues={ListsDefinition} onSubmit={(values, { setSubmitting }) => {}}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field component={TextField} autoFocus sx={{ marginTop: 1.5, marginLeft: 1, width: 500 }} name="EnglishName" label="EnglishName" type="text" required variant="filled" id="txtEnglishName" />
            <Field component={TextField} sx={{ marginTop: 1.5, marginLeft: 1, width: 500 }} name="ArabicName" label="ArabicName" type="text" required variant="filled" id="txtArabicName" />
            <br />
            <Field component={TextField} sx={{ marginTop: 1.5, marginLeft: 1, width: 500 }} name="DefinitionCode" label="DefinitionCode" type="text" required variant="filled" id="txtDefinitionCode" />
            <br />
            <Field component={TextField} sx={{ marginTop: 1.5, marginLeft: 1, width: 500 }} name="Description" label="Description" type="text" required variant="filled" id="txtDescription" />
            <br />
            <Box>
              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcVisualIDLabel">VisualID</InputLabel>
                <Select labelId="slcVisualIDLabel" id="slcVisualID" label="VisualID" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcParentIDLabel">Gear Manifest</InputLabel>
                <Select labelId="slcParentIDLabel" id="slcParentID" label="ParentID" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <InputLabel sx={{ marginTop: 1 }}>
              Color
              <ColorPicker />
            </InputLabel>

            <DataGrid
              rowKeyGetter={rowKeyGetter}
              selectedRows={selectedRows}
              onSelectedRowsChange={setSelectedRows}
              onRowClick={OnRowClick}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
              columns={GetColumns()}
              style={{ blockSize: "100%" }}
              rows={ListsDefinition.Details}
            />

            <ButtonGroup style={{ margin: 5 }} variant="contained">
              <Button color="primary" startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button color="secondary" startIcon={<CancelIcon />}>
                Cancel
              </Button>
              <Button color="error" startIcon={<DeleteIcon />}>
                Delete
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ListsDefinitionView;
