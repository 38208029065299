import { AnyAction, Dispatch, MiddlewareAPI, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { User } from "oidc-client-ts";
import { ApiCallBegan, ApiCallFailed, ApiCallSuccess } from "./ApiActions";
import { ApiBeganDetails } from "./ApiBeganDetails";

function getUser() {
  const oidcStorage = sessionStorage.getItem("oidc.user:https://account.botas.sa:560109b4-ea1b-4202-9a53-78b2cbb3771d");
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

const ControlApiMiddleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next: Dispatch<AnyAction>) =>
  async (action: PayloadAction<ApiBeganDetails, string>) => {
    var _user = getUser();
    if (_user === null) return;
    if (action.type !== ApiCallBegan.type) return next(action);

    const { url, method, data, onStart, onSuccess, onError, tag } = action.payload;
    if (onStart) dispatch({ type: onStart, payload: action.payload });

    axios.defaults.headers.common = { Authorization: `Bearer ${_user.id_token}` };

    const _apiPort = process.env.REACT_APP_API_PORT;

    next(action);
    try {
      const response = await axios.request({
        baseURL: `https://botas.sa:${_apiPort}`,
        url,
        method,
        data,
      });

      dispatch(ApiCallSuccess(response.data));

      if (onSuccess) dispatch({ type: onSuccess, payload: {data: response.data, tag: tag} });
    } catch (error) {
      if (onError) dispatch({ type: onError, payload: error });
      dispatch(ApiCallFailed(error));
    }
  };

export default ControlApiMiddleware;
