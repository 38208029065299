import { ListView, ListViewGroup, ListViewItem, SetPageTitle } from "@arcadia/core.base.ui";
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Launch as LaunchIcon } from "@mui/icons-material";
import { Button, ButtonGroup, Typography } from "@mui/material";
import React from "react";

const CodeTemplateOperations = () => {
  SetPageTitle("Code Templates Operations");

  const [SelectedItem, SetSelectedItem] = React.useState<ListViewItem | undefined>(undefined);

  function ItemClicked(_item: ListViewItem) {
    SetSelectedItem(_item);
  }

  const groups: ListViewGroup[] = [new ListViewGroup("CSharp", "C#", [new ListViewItem("1", "AddParameters", "Core.Item")]), new ListViewGroup("Java", "Java", [new ListViewItem("2", "AddParameters", "Core.Item")]), new ListViewGroup("TypeScript", "TypeScript", [new ListViewItem("3", "AddParameters", "Core.Item")])];

  return (
    <>
      <Typography variant="h3">Code Templates Operations{SelectedItem && " - " + SelectedItem?.Phrase}</Typography>
      <ButtonGroup variant="contained" sx={{ marginTop: 2 }}>
        <Button startIcon={<AddIcon />}>Add</Button>
        {SelectedItem && (
          <>
            <Button startIcon={<EditIcon />}>Edit</Button>
            <Button startIcon={<DeleteIcon />}>Delete</Button>
            <Button startIcon={<LaunchIcon />}>View</Button>
          </>
        )}
      </ButtonGroup>
      <ListView Items={groups} onItemClick={ItemClicked} />
    </>
  );
};

export default CodeTemplateOperations;
