import { Box, Button, ButtonGroup, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Checkbox, TextField } from "formik-mui";
import React from "react";
import { Cancel as CancelIcon, Delete as DeleteIcon, Save as SaveIcon } from "@mui/icons-material";
import PermissionDefinition from "../../../../DataServices/Modals/PermissionDefinition";

interface PermissionDefinitionProps {
  PermissionDefinition: PermissionDefinition;
}

const PermissionDefinitionView = ({ PermissionDefinition }: PermissionDefinitionProps) => {
  return (
    <Box
      sx={{
        minWidth: "350px",
        alignItems: "left",
      }}
    >
      <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.grey[800], borderRadius: 4, paddingLeft: 2, textAlign: "center" }}>
        {PermissionDefinition.Name}
      </Typography>
      <Formik initialValues={PermissionDefinition} onSubmit={(values, { setSubmitting }) => {}}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field component={TextField} autoFocus sx={{ marginTop: 1.5, marginLeft: 1, width: 500 }} name="Name" label="Name" type="text" required variant="filled" id="txtName" />

            <Box>
              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcTitleLabel">Title</InputLabel>
                <Select labelId="slcTitleLabel" id="slcTitle" label="Title" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcDescriptionLabel">Description</InputLabel>
                <Select labelId="slcDescriptionLabel" id="slcDescription" label="Description" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
              <InputLabel id="slcCommandsDefinitionIDLabel">CommandsDefinitionID</InputLabel>
              <Select labelId="slcCommandsDefinitionIDLabel" id="slcCommandsDefinitionID" label="CommandsDefinitionID" value="Core">
                <MenuItem value="Arcadia">Arcadia</MenuItem>
                <MenuItem value="Core">Core</MenuItem>
                <MenuItem value="Providers">Providers</MenuItem>
              </Select>
            </FormControl>
            <br />

            <FormControl component="fieldset" sx={{ margin: 1 }}>
              <FormLabel component="legend">Kind</FormLabel>
              <RadioGroup row={true} name="PermissionKind" value={PermissionDefinition.PermissionKind}>
                <FormControlLabel disabled={true} sx={{ width: 110, display: "inline" }} value="Browse" control={<Radio />} label="Browse" />
                <FormControlLabel disabled={true} sx={{ width: 100, display: "inline" }} value="Add" control={<Radio />} label="Add" />
                <FormControlLabel disabled={true} sx={{ width: 100, display: "inline" }} value="Edit" control={<Radio />} label="Edit" />
                <FormControlLabel disabled={true} sx={{ width: 110, display: "inline" }} value="Delete" control={<Radio />} label="Delete" />
                <FormControlLabel disabled={true} sx={{ width: 100, display: "inline" }} value="Show" control={<Radio />} label="Show" />
                <FormControlLabel disabled={true} sx={{ width: 160, display: "inline" }} value="SettingsGroup" control={<Radio />} label="Settings Group" />
                <FormControlLabel disabled={true} sx={{ width: 100, display: "inline" }} value="Report" control={<Radio />} label="Report" />
                <FormControlLabel disabled={true} sx={{ width: 100, display: "inline" }} value="Custom" control={<Radio />} label="Custom" />
              </RadioGroup>
            </FormControl>
            <Box>
              <InputLabel sx={{ width: 190, display: "inline-block" }}>
                <Field disabled="true" component={Checkbox} margin="normal" name="IsLinkedPermission" type="checkbox" required variant="filled" id="chkIsLinkedPermission" />
                Is Linked Permission
              </InputLabel>
            </Box>
            <br />
            <ButtonGroup style={{ margin: 5 }} variant="contained">
              <Button color="primary" startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button color="secondary" startIcon={<CancelIcon />}>
                Cancel
              </Button>
              <Button color="error" startIcon={<DeleteIcon />}>
                Delete
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PermissionDefinitionView;
