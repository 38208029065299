import { ColoredScrollbars } from "@arcadia/core.base.ui";
import { ViewInAr as ViewInArIcon } from "@mui/icons-material";
import { Box, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import PermissionView from "./PermissionDefinitionsPartials/PermissionDefinitionView";
import PermissionDefinition from "../../../DataServices/Modals/PermissionDefinition";

interface PermissionDefinitionsProps {
  PermissionDefinitions: PermissionDefinition[];
}

const PermissionDefinitions = (props: PermissionDefinitionsProps) => {
  const [selectedPermissionDefinition, setSelectedPermissionDefinition] = React.useState<PermissionDefinition>();

  return (
    <Grid
      direction="row"
      justifyContent="stretch"
      container
      spacing={0.5}
      sx={{
        borderRadius: 2,
        flex: 1,
        height: "100%",
      }}
    >
      <Grid container item direction="column" xs={12} sm={6} md={3} lg={2.5} xl={2.5} sx={{ height: "100%" }}>
        <Grid
          item
          sx={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            backgroundColor: (theme) => theme.palette.background.default,
            display: "flex",
            justifyContent: "center",
            paddingBottom: 2,
            alignItems: "stretch",
            height: "100%",
            padding: 0,
          }}
        >
          <ColoredScrollbars>
            <List>
              {props.PermissionDefinitions.map((_permissionDefinition: PermissionDefinition) => (
                <ListItem key={_permissionDefinition.ID} color="#1E88E5" disablePadding>
                  <ListItemButton onClick={() => setSelectedPermissionDefinition(_permissionDefinition)}>
                    <ListItemIcon>
                      <ViewInArIcon />
                    </ListItemIcon>
                    <ListItemText primary={_permissionDefinition.Name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </ColoredScrollbars>
        </Grid>
      </Grid>

      <Grid
        item
        xs={0}
        sm={6}
        md={9}
        lg={9.5}
        xl={9.5}
        sx={{
          display: {
            xs: "none",
            md: "initial",
          },
        }}
      >
        <Box sx={{ height: "100%" }}>{selectedPermissionDefinition && <ColoredScrollbars>{<PermissionView PermissionDefinition={selectedPermissionDefinition} />}</ColoredScrollbars>}</Box>
      </Grid>
    </Grid>
  );
};

export default PermissionDefinitions;
