import { ListView, ListViewGroup, ListViewItem, SetPageTitle } from "@arcadia/core.base.ui";
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Launch as LaunchIcon } from "@mui/icons-material";
import { Button, ButtonGroup, Typography } from "@mui/material";
import React from "react";

const UIElements = () => {
  SetPageTitle("UI Elements");

  const [SelectedItem, SetSelectedItem] = React.useState<ListViewItem | undefined>(undefined);

  function ItemClicked(_item: ListViewItem) {
    SetSelectedItem(_item);
  }

  const groups: ListViewGroup[] = [
    new ListViewGroup("WinForms", "Windows Forms", [
      new ListViewItem("1", "BsBarcode", "Core.Item"),
      new ListViewItem("2", "BsButton", "Core.Item"),
      new ListViewItem("3", "BsCheckBox", "Core.Item"),
      new ListViewItem("4", "BsColorPicker", "Core.Item"),
      new ListViewItem("5", "BsColumnHeader", "Core.Item"),
      new ListViewItem("6", "BsComboList", "Core.Item"),
      new ListViewItem("7", "BsDataGridView", "Core.Item"),
      new ListViewItem("8", "BsDateTimePicker", "Core.Item"),
      new ListViewItem("9", "BsFontPicker", "Core.Item"),
      new ListViewItem("10", "BsGuid", "Core.Item"),
      new ListViewItem("11", "BsImagePicker", "Core.Item"),
      new ListViewItem("12", "BsLabel", "Core.Item"),
      new ListViewItem("13", "BsTextBox", "Core.Item"),
      new ListViewItem("14", "BsTextArea", "Core.Item"),
      new ListViewItem("15", "BsNumberBox", "Core.Item"),
    ]),
  ];

  return (
    <>
      <Typography variant="h3">UI Elements{SelectedItem && " - " + SelectedItem?.Phrase}</Typography>
      <ButtonGroup variant="contained" sx={{ marginTop: 2 }}>
        <Button startIcon={<AddIcon />}>Add</Button>
        {SelectedItem && (
          <>
            <Button startIcon={<EditIcon />}>Edit</Button>
            <Button startIcon={<DeleteIcon />}>Delete</Button>
            <Button startIcon={<LaunchIcon />}>View</Button>
          </>
        )}
      </ButtonGroup>
      <ListView Items={groups} onItemClick={ItemClicked} />
    </>
  );
};

export default UIElements;
