import React from "react";
import { Backdrop as MUIBackdrop, Typography, useTheme } from "@mui/material";
import { Triangle } from "react-loader-spinner";

interface BackdropProps {
  open: boolean;
}

const Backdrop = ({ open }: BackdropProps) => {
  const theme = useTheme();
  return (
    <MUIBackdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: "flex",
        flexDirection: "column",
      }}
      open={open}
    >
      <Triangle color={theme.palette.primary.main} width="250" height="250" ariaLabel="loading" />
      <Typography variant="h2">Please wait ...</Typography>
    </MUIBackdrop>
  );
};

export default Backdrop;
