import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import thunk from "redux-thunk";
import ControlApiMiddleware from "./Api/ControlApiMiddleware";
import GearsManifestsReducer from "./Slices/GearsManifestsSlice";
import ListsDefinitionsReducer from "./Slices/ListsDefinitionsSlice";
import DataUnitsReducer from "./Slices/DataUnitsSlice";
import EnumeratorsReducer from "./Slices/EnumeratorsSlice";

const DataStore = configureStore({
  reducer: {
    GearsManifests: GearsManifestsReducer,
    ListsDefinitions: ListsDefinitionsReducer,
    DataUnits: DataUnitsReducer,
    Enumerators: EnumeratorsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(ControlApiMiddleware).concat(thunk),
});

export type RootState = ReturnType<typeof DataStore.getState>;
export type AppDispatch = typeof DataStore.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default DataStore;
