import { Add as AddIcon } from "@mui/icons-material";
import { Alert, Box, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import DataGrid, { Column } from "react-data-grid";
import { Cancel as CancelIcon, Delete as DeleteIcon, Save as SaveIcon } from "@mui/icons-material";
import Validation from "../../../../../../DataServices/Modals/Validation";
import { FieldProps } from "./FieldProperties";

const FieldValidations = ({ FieldData }: FieldProps) => {
  const _validation: Validation = new Validation();

  function GetColumns(): readonly Column<Validation>[] {
    return [
      { key: "ID", name: "ValidationID", width: 100 },
      { key: "ValidationDefinitionID", name: "ValidationDefinitionID", width: 80 },
      { key: "FirstCondition", name: "FirstCondition", width: 80 },
      { key: "SecondCondition", name: "SecondCondition", width: 80 },
      { key: "FirstConditionType", name: "FirstConditionType", width: 80 },
      { key: "SecondConditionType", name: "SecondConditionType", width: 80 },
      { key: "ConditionCompareType", name: "ConditionCompareType", width: 80 },
      { key: "MessageID", name: "MessageID", width: 80 },
      { key: "SuggestionID", name: "SuggestionID", width: 80 },
    ];
  }

  const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<string>>(() => new Set());
  function OnRowClick(row: Validation, column: Column<Validation, unknown>) {
    setSelectedRows(new Set<string>([row.ID]));
  }
  function rowKeyGetter(row: Validation) {
    return row.ID;
  }

  return (
    <Box
      sx={{
        minWidth: "350px",
        alignItems: "left",
      }}
    >
      <Typography variant="h5" sx={{ backgroundColor: (theme) => theme.palette.info.main, borderRadius: 4, padding: 1 }}>
        Field Validations
      </Typography>
      <Formik initialValues={_validation} onSubmit={(values, { setSubmitting }) => {}}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.background.default, padding: 1, marginTop: 3 }}>
              Validations
            </Typography>

            <DataGrid
              rowKeyGetter={rowKeyGetter}
              selectedRows={selectedRows}
              onSelectedRowsChange={setSelectedRows}
              onRowClick={OnRowClick}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
              columns={GetColumns()}
              style={{ blockSize: "100%" }}
              rows={FieldData.Validations}
            />

            <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.background.default, padding: 1, marginTop: 3 }}>
              Validation Properties
            </Typography>

            <Field component={TextField} name="FirstCondition" label="First Condition" type="text" sx={{ marginTop: 1.5, width: "50%" }} variant="filled" id="txtFirstCondition" />
            <FormControl variant="filled" sx={{ marginTop: 1, width: "50%" }}>
              <InputLabel id="slcFirstConditionTypeLabel">First Condition Type</InputLabel>
              <Select labelId="slcFirstConditionTypeLabel" id="slcFirstConditionType" label="FirstConditionType" defaultValue="3" style={{ margin: 3 }}>
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>

            <Field component={TextField} name="SecondCondition" label="Second Condition" type="text" sx={{ marginTop: 1.5, width: "50%" }} variant="filled" id="txtSecondCondition" />

            <FormControl variant="filled" sx={{ marginTop: 1, width: "50%" }}>
              <InputLabel id="slcSecondConditionTypeLabel">Second Condition Type</InputLabel>
              <Select labelId="slcSecondConditionTypeLabel" id="slcSecondConditionType" label="SecondConditionType" defaultValue="3" style={{ margin: 3 }}>
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
              <InputLabel id="slcConditionLabel">Condition</InputLabel>
              <Select labelId="slcConditionLabel" id="slcCondition" label="Condition" defaultValue="3" style={{ margin: 3 }}>
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
              <InputLabel id="slcTypeLabel">Type</InputLabel>
              <Select labelId="slcTypeLabel" id="slcType" label="Type" defaultValue="3" style={{ margin: 3 }}>
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.background.default, padding: 1, marginTop: 3 }}>
              Result
            </Typography>

            <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
              <InputLabel id="slcResultLabel">Result</InputLabel>
              <Select labelId="slcResultLabel" id="slcResult" label="Result" defaultValue="3" style={{ margin: 3 }}>
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
              <InputLabel id="slcSuggestionLabel">Suggestion</InputLabel>
              <Select labelId="slcSuggestionLabel" id="slcSuggestion" label="Suggestion" defaultValue="3" style={{ margin: 3 }}>
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.background.default, padding: 1, marginTop: 3 }}>
              Validation Preset
            </Typography>

            <FormControl variant="filled" sx={{ marginTop: 1, width: 350 }}>
              <InputLabel id="slcValidationPresetLabel">Validation Preset</InputLabel>
              <Select labelId="slcValidationPresetLabel" id="slcValidationPreset" label="ValidationPreset" defaultValue="3">
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>

            <Button color="primary" variant="contained" sx={{ margin: 2 }}>
              <AddIcon />
            </Button>

            <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.background.default, padding: 1, marginTop: 3 }}>
              Properties
            </Typography>

            <Alert severity="info">No properties found!</Alert>

            <ButtonGroup style={{ margin: 5 }} variant="contained">
              <Button color="primary" startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button color="secondary" startIcon={<CancelIcon />}>
                Cancel
              </Button>
              <Button color="error" startIcon={<DeleteIcon />}>
                Delete
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default FieldValidations;
