import { ColoredScrollbars, StyledTreeItem } from "@arcadia/core.base.ui";
import { ArrowDropDown as ArrowDropDownIcon, ArrowRight as ArrowRightIcon, AutoAwesomeMosaic as AutoAwesomeMosaicIcon, AutoAwesomeMotion as AutoAwesomeMotionIcon, Settings as SettingsIcon } from "@mui/icons-material";
import { TreeView } from "@mui/lab";
import { Grid } from "@mui/material";
import React from "react";
import SettingsGroupView from "./SettingsPartials/SettingsGroupView";
import SettingsSetView from "./SettingsPartials/SettingsSetView";
import SettingView from "./SettingsPartials/SettingView";
import SettingsGroup from "../../../DataServices/Modals/SettingsGroup";
import SettingsSet from "../../../DataServices/Modals/SettingsSet";
import SettingDefinition from "../../../DataServices/Modals/SettingDefinition";

interface SettingsGroupsProps {
  SettingsGroups: SettingsGroup[];
}

function GetSelectedPartial(selectedPartial: string, selectedItemId: string, props: SettingsGroupsProps): JSX.Element {
  if (selectedItemId === "") return <>&lt;&lt;&lt;&lt; Please select an item to display..</>;
  switch (selectedPartial) {
    case "SettingsGroup":
      const _settingsGroup = props.SettingsGroups.filter((__settingsGroup) => __settingsGroup.ID === selectedItemId);
      if (_settingsGroup.length === 0) return <>Ops, SettingsGroup not found in props!</>;
      return <SettingsGroupView SettingsGroup={_settingsGroup[0]} />;
    case "SettingsSet":
      var _settingsSet: SettingsSet | null = null;
      props.SettingsGroups.forEach((settingsGroup: SettingsGroup) => {
        const _settingsSetFiltered = settingsGroup.Sets.filter((__settingsSet) => __settingsSet.ID === selectedItemId);
        if (_settingsSetFiltered.length !== 0) _settingsSet = _settingsSetFiltered[0];
      });
      if (_settingsSet === null) return <>Ops, SettingsSet not found in props!</>;
      return <SettingsSetView SettingsSet={_settingsSet} />;
    case "Setting":
      var _settingDefinition: SettingDefinition | null = null;
      props.SettingsGroups.forEach((settingsGroup: SettingsGroup) => {
        settingsGroup.Sets.forEach((settingsSet: SettingsSet) => {
          const _settingDefinitionFiltered = settingsSet.Settings.filter((__settingDefinition) => __settingDefinition.ID === selectedItemId);
          if (_settingDefinitionFiltered.length !== 0) _settingDefinition = _settingDefinitionFiltered[0];
        });
      });
      if (_settingDefinition === null) return <>Ops, SettingDefinition not found in props!</>;
      return <SettingView SettingDefinition={_settingDefinition} />;
    default:
      throw new Error(`Invalid selectedPartial: ${selectedPartial}`);
  }
}
const Settings = (props: SettingsGroupsProps) => {
  const [selectedPartial, setSelectedPartial] = React.useState("SettingsGroup");
  const [selectedItemId, setSelectedItemId] = React.useState("");

  function OnItemSelected(event: React.SyntheticEvent, nodeId: string) {
    if (nodeId.startsWith("g_")) setSelectedPartial("SettingsGroup");
    else if (nodeId.startsWith("t_")) setSelectedPartial("SettingsSet");
    else if (nodeId.startsWith("s_")) setSelectedPartial("Setting");
    else throw new Error("Selected TreeNode is not recognized!");

    const _id = nodeId.substring(2);
    setSelectedItemId(_id);
  }

  return (
    <Grid
      direction="row"
      justifyContent="stretch"
      container
      spacing={0.5}
      sx={{
        borderRadius: 2,
        flex: 1,
        height: "100%",
      }}
    >
      <Grid container item direction="column" xs={12} sm={6} md={3} lg={2} xl={2} sx={{ height: "100%" }}>
        <Grid
          item
          sx={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            backgroundColor: (theme) => theme.palette.background.default,
            display: "flex",
            justifyContent: "center",
            paddingBottom: 2,
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <ColoredScrollbars>
            <TreeView onNodeSelect={OnItemSelected} defaultExpanded={["3"]} defaultCollapseIcon={<ArrowDropDownIcon />} defaultExpandIcon={<ArrowRightIcon />} defaultEndIcon={<div style={{ width: 24 }} />} sx={{ height: "100%", flexGrow: 1, maxWidth: 400, overflowY: "auto" }}>
              {props.SettingsGroups.map((_settingsGroup: SettingsGroup) => (
                <StyledTreeItem key={`g_${_settingsGroup.ID}`} nodeId={`g_${_settingsGroup.ID}`} color={_settingsGroup.Color} labelIcon={AutoAwesomeMosaicIcon} labelText={_settingsGroup.Name}>
                  {_settingsGroup.Sets.map((_settingsSet: SettingsSet) => (
                    <StyledTreeItem key={`t_${_settingsSet.ID}`} nodeId={`t_${_settingsSet.ID}`} color={_settingsSet.Color} labelIcon={AutoAwesomeMotionIcon} labelText={_settingsSet.Name}>
                      {_settingsSet.Settings.map((_settingDefinition: SettingDefinition) => (
                        <StyledTreeItem key={`s_${_settingDefinition.ID}`} nodeId={`s_${_settingDefinition.ID}`} color={_settingsSet.Color} labelIcon={SettingsIcon} labelText={_settingDefinition.Name} />
                      ))}
                    </StyledTreeItem>
                  ))}
                </StyledTreeItem>
              ))}
            </TreeView>
          </ColoredScrollbars>
        </Grid>
      </Grid>

      <Grid
        item
        xs={0}
        sm={6}
        md={9}
        lg={10}
        xl={10}
        sx={{
          display: {
            xs: "none",
            md: "initial",
          },
        }}
      >
        {GetSelectedPartial(selectedPartial, selectedItemId, props)}
      </Grid>
    </Grid>
  );
};

export default Settings;
