import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { ColoredScrollbars } from "@arcadia/core.base.ui";
import FieldProperties, { FieldProps } from "./FieldProperties";
import FieldValidations from "./FieldValidations";

const FieldView = ({ FieldData }: FieldProps) => {
  const [selectedTab, setSelectedTab] = React.useState("Properties");

  function OnTabChanged(event: React.SyntheticEvent, newValue: string) {
    setSelectedTab(newValue);
  }

  return (
    <>
      <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.grey[800], borderRadius: 4, paddingLeft: 2, textAlign: "center" }}>
        {FieldData.ColumnName}
      </Typography>
      <Box sx={{ height: "calc(100% - 32px)" }}>
        <ColoredScrollbars>
          <TabContext value={selectedTab}>
            <Tabs onChange={OnTabChanged} value={selectedTab} textColor="secondary" indicatorColor="secondary" variant="scrollable" scrollButtons="auto">
              <Tab value="Properties" label="Properties" />
              <Tab value="Validations" label="Validations" />
            </Tabs>

            <TabPanel value="Properties" sx={{ height: "calc(100% - 48px)", padding: 1 }}>
              <FieldProperties FieldData={FieldData} />
            </TabPanel>
            <TabPanel value="Validations" sx={{ height: "calc(100% - 48px)", padding: 1 }}>
              <FieldValidations FieldData={FieldData} />
            </TabPanel>
          </TabContext>
        </ColoredScrollbars>
      </Box>
    </>
  );
};

export default FieldView;
