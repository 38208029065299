import { ColoredScrollbars, RequireAuth, SetPageTitle } from "@arcadia/core.base.ui";
import { Button, ButtonGroup, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import {
  Add as AddIcon,
  Refresh as RefreshIcon,
  Apps as AppsIcon,
  Gamepad as GamepadIcon,
  Settings as SettingsIcon,
  FormatListNumbered as FormatListNumberedIcon,
  Policy as PolicyIcon,
  Translate as TranslateIcon,
  Image as ImageIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Storage as StorageIcon,
} from "@mui/icons-material";
import React from "react";
import DataUnits from "./Partials/DataUnits";
import CommandsDefinitions from "./Partials/CommandsDefinitions";
import Settings from "./Partials/Settings";
import Enumerators from "./Partials/Enumerators";
import PermissionDefinitions from "./Partials/PermissionDefinitions";
import Phrases from "./Partials/Phrases";
import Visuals from "./Partials/Visuals";
import { LoadGearsManifests } from "../../DataServices/DataApi/Slices/GearsManifestsSlice";
import { useAppDispatch, useAppSelector } from "../../DataServices/DataApi/DataStore";
import GearsManifest from "../../DataServices/Modals/GearsManifest";
import Backdrop from "../../Backdrop";
import Component from "../../DataServices/Modals/Component";

const GearsManifests = () => {
  SetPageTitle("Gear Manifests");

  const [selectedPartial, setSelectedPartial] = React.useState("DataUnits");
  const [selectedGearManifest, setSelectedGearManifest] = React.useState<string>("");
  const [selectedComponent, setSelectedComponent] = React.useState<string>("632a9ba0a3575f8143987c3f");

  const GearManifest_Changed = (event: SelectChangeEvent) => {
    setSelectedGearManifest(event.target.value);
    const _gearManifest = _gearsManifests.GearManifests.filter((__gearManifest) => __gearManifest.ID === event.target.value)[0];
    if (_gearManifest.Components.length !== 0) setSelectedComponent(_gearManifest.Components[0].ID);
  };

  const Component_Changed = (event: SelectChangeEvent) => {
    setSelectedComponent(event.target.value);
  };

  function GetSelectedPartial(selectedPartial: string): JSX.Element {
    const _gearManifestId = selectedGearManifest;
    if (_gearManifestId === "") return <></>;

    const _selectedGearManifest = _gearsManifests.GearManifests.filter((__gearManifest) => __gearManifest.ID === _gearManifestId);
    if (_selectedGearManifest.length === 0) return <></>;

    const _components = _selectedGearManifest[0].Components.filter((__component) => __component.ID === selectedComponent);

    switch (selectedPartial) {
      case "DataUnits":
        return <DataUnits Id={selectedComponent} />;
      case "CommandsDefinitions":
        return <CommandsDefinitions CommandsDefinitions={_components[0].CommandsDefinitions} />;
      case "Settings":
        return <Settings SettingsGroups={_components[0].SettingsGroups} />;
      case "Enumerators":
        return <Enumerators Id={_gearManifestId} />;
      case "Permissions":
        return <PermissionDefinitions PermissionDefinitions={_components[0].PermissionsDefinitions} />;
      case "Phrases":
        return <Phrases Id={_gearManifestId} />;
      case "Visuals":
        return <Visuals Id={_gearManifestId} />;
      default:
        throw new Error(`Invalid selectedPartial: ${selectedPartial}`);
    }
  }

  const _gearsManifests = useAppSelector((state) => state.GearsManifests);

  const dispatch = useAppDispatch();
  function LoadData() {
    dispatch(LoadGearsManifests());
  }
  React.useEffect(() => {
    LoadData();
  }, [1]);

  if (selectedGearManifest === "" && _gearsManifests.GearManifests.length !== 0) setSelectedGearManifest("632a98dea3575f81439601cf");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <RequireAuth>
      <>
        <Backdrop open={_gearsManifests.IsLoading} />
        <Grid
          justifyContent="stretch"
          alignContent="stretch"
          container
          spacing={0.5}
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            borderRadius: 2,
            height: `100%`,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                height: 40,
                backgroundColor: (theme) => theme.palette.background.default,
                padding: 0.5,
                margin: 0.5,
                borderRadius: 3,
                alignItems: "center",
              }}
            >
              <ButtonGroup size="small" variant="contained" sx={{ marginLeft: 1 }}>
                <Button>
                  <AddIcon /> New Gear Manifest
                </Button>
                <Button onClick={handleClick}>
                  <MoreVertIcon />
                </Button>
                <Button>
                  <RefreshIcon />
                </Button>
              </ButtonGroup>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <AppsIcon fontSize="small" />
                  </ListItemIcon>
                  New Component
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  New Settings Group
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <GamepadIcon fontSize="small" />
                  </ListItemIcon>
                  New Command Definition
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <PolicyIcon fontSize="small" />
                  </ListItemIcon>
                  New Permission Definition
                </MenuItem>
              </Menu>

              <Select id="slcGearManifest" value={selectedGearManifest} onChange={GearManifest_Changed} sx={{ width: "170px", textAlign: "center", height: 38 }}>
                {_gearsManifests.GearManifests.map((_gearManifest: GearsManifest) => (
                  <MenuItem value={_gearManifest.ID} key={_gearManifest.ID}>
                    {_gearManifest.Name}
                  </MenuItem>
                ))}
              </Select>

              {selectedGearManifest !== "" && (
                <>
                  <ButtonGroup size="small" variant="text">
                    <Button>
                      <EditIcon />
                    </Button>
                    <Button color="error">
                      <DeleteIcon />
                    </Button>
                  </ButtonGroup>

                  <Select id="slcComponent" value={selectedComponent} onChange={Component_Changed} sx={{ width: "150px", textAlign: "center", height: 38 }}>
                    {_gearsManifests.GearManifests.filter((__gearManifest) => __gearManifest.ID === selectedGearManifest)[0].Components.map((_component: Component) => (
                      <MenuItem value={_component.ID} key={_component.ID}>
                        {_component.Name}
                      </MenuItem>
                    ))}
                  </Select>

                  {selectedComponent !== "" && (
                    <ButtonGroup size="small" variant="text">
                      <Button>
                        <EditIcon />
                      </Button>
                      <Button color="error">
                        <DeleteIcon />
                      </Button>
                    </ButtonGroup>
                  )}
                </>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5} sx={{ height: "calc(100% - 64px)" }}>
            <ColoredScrollbars>
              <List>
                {selectedComponent && (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => setSelectedPartial("DataUnits")}>
                        <ListItemIcon>
                          <StorageIcon />
                        </ListItemIcon>
                        <ListItemText primary="DataUnits" />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemButton onClick={() => setSelectedPartial("CommandsDefinitions")}>
                        <ListItemIcon>
                          <GamepadIcon />
                        </ListItemIcon>
                        <ListItemText primary="Commands Definitions" />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemButton onClick={() => setSelectedPartial("Permissions")}>
                        <ListItemIcon>
                          <PolicyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Permissions" />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemButton onClick={() => setSelectedPartial("Settings")}>
                        <ListItemIcon>
                          <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                      </ListItemButton>
                    </ListItem>
                  </>
                )}
                <ListItem disablePadding>
                  <ListItemButton onClick={() => setSelectedPartial("Enumerators")}>
                    <ListItemIcon>
                      <FormatListNumberedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Enumerators" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton onClick={() => setSelectedPartial("Phrases")}>
                    <ListItemIcon>
                      <TranslateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Phrases" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton onClick={() => setSelectedPartial("Visuals")}>
                    <ListItemIcon>
                      <ImageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Visuals" />
                  </ListItemButton>
                </ListItem>
              </List>
            </ColoredScrollbars>
          </Grid>

          <Grid
            item
            xs={0}
            sm={6}
            md={9}
            lg={10}
            xl={10.5}
            sx={{
              display: {
                xs: "none",
                md: "initial",
              },
            }}
          >
            {GetSelectedPartial(selectedPartial)}
          </Grid>
        </Grid>
      </>
    </RequireAuth>
  );
};

export default GearsManifests;
