const url = `${window.location.origin}/Login`;

export const OidcSettings = {
  authority: "https://account.botas.sa",
  client_id: "560109b4-ea1b-4202-9a53-78b2cbb3771d",
  client_secret: "{98711FA9-B6B1-4ECE-9E6E-8D47CF6478E6}",

  //authority: "https://accountstaging.botas.sa",
  //client_id: "560109b4-ea1b-4202-9a53-78b2cbb3771d",
  //client_secret: "54860941-ddac-4e58-9155-f3c138b937f2",
  redirect_uri: url,
  post_logout_redirect_uri: url,
  response_type: "code",
  scope: "email api phone address profile openid",

  response_mode: "fragment",

  filterProtocolClaims: true,
};