import { Box, Button, ButtonGroup, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Checkbox, TextField } from "formik-mui";
import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/theme-gruvbox";
import "ace-builds/src-noconflict/ext-language_tools";
import { Cancel as CancelIcon, Delete as DeleteIcon, Save as SaveIcon } from "@mui/icons-material";
import CommandsDefinition from "../../../../DataServices/Modals/CommandsDefinition";

interface CommandsDefinitionProps {
  CommandDefinition: CommandsDefinition;
}

const CommandsDefinitionView = ({ CommandDefinition }: CommandsDefinitionProps) => {
  console.log(CommandDefinition);
  return (
    <Box
      sx={{
        minWidth: "350px",
        alignItems: "left",
      }}
    >
      <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.grey[800], borderRadius: 4, paddingLeft: 2, textAlign: "center" }}>
        {CommandDefinition.Name}
      </Typography>
      <Formik initialValues={CommandDefinition} onSubmit={(values, { setSubmitting }) => {}}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field component={TextField} autoFocus sx={{ marginTop: 1.5, marginLeft: 1, width: 500 }} name="Name" label="Name" type="text" required variant="filled" id="txtName" />

            <Box>
              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcTitleLabel">Title</InputLabel>
                <Select labelId="slcTitleLabel" id="slcTitle" label="Title" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcDescriptionLabel">Description</InputLabel>
                <Select labelId="slcDescriptionLabel" id="slcDescription" label="Description" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcVisualLabel">Visual</InputLabel>
                <Select labelId="slcVisualLabel" id="slcVisual" label="Visual" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <FormControl component="fieldset" sx={{ margin: 1 }}>
              <FormLabel component="legend">Type</FormLabel>
              <RadioGroup row={true} name="CommandType" value={CommandDefinition.CommandType}>
                <FormControlLabel disabled={true} value="Normal" control={<Radio />} label="Normal" />
                <FormControlLabel disabled={true} value="Linked" control={<Radio />} label="Linked" />
              </RadioGroup>
            </FormControl>
            <Box>
              Execute Code:
              <AceEditor style={{ width: "100%", height: "150px" }} mode="csharp" theme="gruvbox" fontSize={15} name="ExecuteCode" value={CommandDefinition.ExecuteCode} showPrintMargin={false} editorProps={{ $blockScrolling: true, enableBasicAutocompletion: true, enableLiveAutocompletion: true }} />
            </Box>

            <Box>
              Execute Code with Params:
              <AceEditor style={{ width: "100%", height: "150px" }} mode="csharp" theme="gruvbox" fontSize={15} name="ExecuteCodeParametered" value={CommandDefinition.ExecuteCodeParametered} showPrintMargin={false} editorProps={{ $blockScrolling: true, enableBasicAutocompletion: true, enableLiveAutocompletion: true }} />
            </Box>

            <FormControl component="fieldset" sx={{ margin: 1 }}>
              <FormLabel component="legend">Kind</FormLabel>
              <RadioGroup row={true} name="CommandKind" value={CommandDefinition.CommandKind}>
                <FormControlLabel disabled={true} sx={{ width: 140, display: "inline" }} value="Browse" control={<Radio />} label="Browse" />
                <FormControlLabel disabled={true} sx={{ width: 140, display: "inline" }} value="Add" control={<Radio />} label="Add" />
                <FormControlLabel disabled={true} sx={{ width: 140, display: "inline" }} value="Edit" control={<Radio />} label="Edit" />
                <FormControlLabel disabled={true} sx={{ width: 140, display: "inline" }} value="Delete" control={<Radio />} label="Delete" />
                <FormControlLabel disabled={true} sx={{ width: 140, display: "inline" }} value="Show" control={<Radio />} label="Show" />
                <FormControlLabel disabled={true} sx={{ width: 140, display: "inline" }} value="Report" control={<Radio />} label="Report" />
                <FormControlLabel disabled={true} sx={{ width: 140, display: "inline" }} value="Custom" control={<Radio />} label="Custom" />
              </RadioGroup>
            </FormControl>
            <Box>
              <InputLabel sx={{ width: 110, display: "inline-block" }}>
                <Field component={Checkbox} margin="normal" name="IsGeneral" type="checkbox" required variant="filled" id="chkGeneral" />
                General
              </InputLabel>

              <InputLabel sx={{ width: 100, display: "inline-block" }}>
                <Field component={Checkbox} margin="normal" name="IsReport" type="checkbox" required variant="filled" id="chkReport" />
                Report
              </InputLabel>

              <InputLabel sx={{ width: 120, display: "inline-block" }}>
                <Field component={Checkbox} margin="normal" name="IsToolbar" type="checkbox" required variant="filled" id="chkToolbar" />
                Toolbar
              </InputLabel>

              <InputLabel sx={{ width: 130, display: "inline-block" }}>
                <Field component={Checkbox} margin="normal" name="IsDefinition" type="checkbox" required variant="filled" id="chkDefinition" />
                Definition
              </InputLabel>

              <InputLabel sx={{ width: 160, display: "inline-block" }}>
                <Field component={Checkbox} margin="normal" name="IsCommandLine" type="checkbox" required variant="filled" id="chkCommandLine" />
                CommandLine
              </InputLabel>

              <InputLabel sx={{ width: 110, display: "inline-block" }}>
                <Field component={Checkbox} margin="normal" name="IsSetting" type="checkbox" required variant="filled" id="chkSetting" />
                Setting
              </InputLabel>

              <InputLabel sx={{ width: 140, display: "inline-block" }}>
                <Field component={Checkbox} margin="normal" name="IsMainMenu" type="checkbox" required variant="filled" id="chkMainMenu" />
                MainMenu
              </InputLabel>

              <InputLabel sx={{ width: 100, display: "inline-block" }}>
                <Field component={Checkbox} margin="normal" name="IsTool" type="checkbox" required variant="filled" id="chkTool" />
                Tool
              </InputLabel>
            </Box>
            <br />
            <ButtonGroup style={{ margin: 5 }} variant="contained">
              <Button color="primary" startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button color="secondary" startIcon={<CancelIcon />}>
                Cancel
              </Button>
              <Button color="error" startIcon={<DeleteIcon />}>
                Delete
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CommandsDefinitionView;
