import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { toast } from "react-toastify";
import ListsDefinition from "../../Modals/ListsDefinition";
import { ApiCallBegan } from "../Api/ApiActions";
import { AppDispatch, RootState } from "../DataStore";

interface InitialState {
  ListDefinitions: ListsDefinition[];
  IsLoading: boolean;
  LastFetch?: number | null;
}

export const LoadListsDefinitions = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { LastFetch } = getState().ListsDefinitions;

  const diffInMinuts = moment().diff(moment(LastFetch), "minutes");

  if (diffInMinuts < 10) return;

  dispatch(
    ApiCallBegan({
      url: "CodeGeneration/ListsDefinitions",
      onSuccess: ListsDefinitionsSlice.actions.ListsDefinitionsLoaded.type,
      onStart: ListsDefinitionsRequested.type,
      onError: ListsDefinitionsLoadError.type,
    })
  );
};

export const addListsDefinition = (ListsDefinition: ListsDefinition) =>
  ApiCallBegan({
    url: "/ListsDefinitions",
    method: "post",
    data: ListsDefinition,
    onSuccess: ListsDefinitionAdded.type,
  });

const ListsDefinitionsSlice = createSlice({
  name: "ListsDefinitions",
  initialState: {
    ListDefinitions: [],
    IsLoading: false,
    LastFetch: null,
    LastCall: null,
  } as InitialState,
  reducers: {
    ListsDefinitionsRequested: (ListsDefinitions, action) => {
      ListsDefinitions.IsLoading = true;
    },
    ListsDefinitionAdded(ListsDefinitions, action) {
      ListsDefinitions.ListDefinitions.push(action.payload.data);
    },
    ListsDefinitionsLoaded(state, action) {
      state.ListDefinitions = action.payload.data;
      state.IsLoading = false;
      state.LastFetch = Date.now();
    },
    ListsDefinitionsLoadError(state, payload) {
      state.ListDefinitions = [];
      state.IsLoading = false;
      state.LastFetch = null;
      toast.error(`ops error: ${payload}`);
    },
  },
});

const { ListsDefinitionAdded, ListsDefinitionsRequested, ListsDefinitionsLoadError } = ListsDefinitionsSlice.actions;
export default ListsDefinitionsSlice.reducer;
