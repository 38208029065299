import { Button, Paper } from "@mui/material";
import React from "react";
import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";
import { SetPageTitle } from "@arcadia/core.base.ui";
import Backdrop from "../Backdrop";

interface LocationState {
  from?: any;
}

const Login = () => {
  let auth = useAuth();

  let location = useLocation();
  let navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  let locationState: LocationState = location.state as LocationState;
  let from = locationState?.from?.pathname || "/";

  React.useEffect(() => {
    SetPageTitle("Login");
    if (auth.isAuthenticated) {
      setIsLoading(true);
      let storedRedirectionUri = localStorage.getItem("redirect_after_authentication");
      let from = storedRedirectionUri || "/";
      navigate(from, { replace: true });
    } else if (!auth.isLoading) {
      async function doLogin() {
        setIsLoading(true);
        localStorage.setItem("redirect_after_authentication", from);
        await auth.signinRedirect();
      }

      doLogin();
    }
  }, [setIsLoading, auth.isAuthenticated, navigate, from, auth]);

  async function Login() {
    setIsLoading(true);
    localStorage.setItem("redirect_after_authentication", from);
    await auth.signinRedirect();
  }

  return (
    <>
      <Backdrop open={isLoading} />

      <Paper
        elevation={5}
        sx={{
          marginTop: 1,
          marginBottom: 1,
          padding: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <img src="./assets/logo.png" alt="Arcadia Logo" style={{ width: 100, marginRight: 20 }} />

        <Button onClick={Login} variant="contained" color="primary" size="large" style={{ margin: 20 }}>
          Login
        </Button>
      </Paper>
    </>
  );
};

export default Login;
