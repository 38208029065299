import { ObjectBase } from "@arcadia/core.data";

export default class Validation extends ObjectBase {
  Commit(): void {
    throw new Error("Method not implemented.");
  }
  Revert(): void {
    throw new Error("Method not implemented.");
  }
  ValidationID: string = "";
  FieldID: string = "";
  ValidationDefinitionID: string = "";
  FirstCondition: string = "";
  SecondCondition: string = "";
  FirstConditionType: string = "";
  SecondConditionType: string = "";
  ConditionCompareType: string = "";
  MessageID: string = "";
  SuggestionID: string = "";
}
