import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import Enumerator from "../../Modals/Enumerator";
import { ApiCallBegan } from "../Api/ApiActions";
import { AppDispatch, RootState } from "../DataStore";

interface GearManifestEnumerators {
  Enumerators: Enumerator[];
  LastFetch?: number | null;
  GearManifestId?: string | null;
}
interface InitialState {
  GearManifestEnumerators: { [id: string]: GearManifestEnumerators };
  IsLoading: boolean;
}
export const LoadEnumerators = (ID: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const GearManifestEnumerators = getState().Enumerators.GearManifestEnumerators[ID];

  if (GearManifestEnumerators != null) {
    const diffInMinutss = moment().diff(moment(GearManifestEnumerators.LastFetch), "minutes");
    if (diffInMinutss < 10 && GearManifestEnumerators.GearManifestId === ID) return;
  }

  dispatch(
    ApiCallBegan({
      url: `CodeGeneration/Enumerators/${ID}`,
      onSuccess: EnumeratorsSlice.actions.EnumeratorsLoaded.type,
      onStart: EnumeratorsRequested.type,
      tag: ID,
    })
  );
};

export const AddEnumerator = (Enumerator: Enumerator) => async (dispatch: AppDispatch, getState: () => RootState) =>
  dispatch(
    ApiCallBegan({
      url: "CodeGeneration/Enumerators",
      method: "post",
      data: Enumerator,
      onSuccess: EnumeratorAdded.type,
      onStart: EnumeratorAddRequested.type,
    })
  );

export const DeleteEnumerator = (ID: string) => async (dispatch: AppDispatch, getState: () => RootState) =>
  dispatch(
    ApiCallBegan({
      url: `CodeGeneration/Enumerators/${ID}`,
      method: "delete",
      onSuccess: EnumeratorDeleted.type,
      onStart: EnumeratorDeleteRequested.type,
    })
  );

export const UpdateEnumerator = (Enumerator: Enumerator) => async (dispatch: AppDispatch, getState: () => RootState) =>
  dispatch(
    ApiCallBegan({
      url: "CodeGeneration/Enumerators",
      method: "patch",
      data: Enumerator,
      onSuccess: EnumeratorUpdated.type,
      onStart: EnumeratorUpdateRequested.type,
    })
  );

export async function GetEnumerator(ID: string): Promise<Enumerator | null> {
  const _apiPort = process.env.REACT_APP_API_PORT;

  const response = await axios.request({
    baseURL: `https://botas.sa:${_apiPort}`,
    url: "CodeGeneration/Enumerators/Get/" + ID,
    method: "get",
  });
  return response.data;
}

const EnumeratorsSlice = createSlice({
  name: "Enumerators",
  initialState: {
    GearManifestEnumerators: {},
    IsLoading: false,
  } as InitialState,
  reducers: {
    EnumeratorsRequested: (Enumerators, action) => {
      Enumerators.IsLoading = true;
    },
    EnumeratorDeleteRequested: (state, action) => {
      toast.loading("Deleting Enumerator please wait...", { autoClose: false, toastId: "DeleteEnumerator_", type: toast.TYPE.INFO });
    },
    EnumeratorAddRequested: (state, action) => {},
    EnumeratorUpdateRequested: (state, action) => {},
    EnumeratorAdded(state, action) {
      // state.Enumerators.push(action.payload);
    },
    EnumeratorUpdated(state, action) {
      // state.Enumerators.push(action.payload);
    },
    EnumeratorDeleted(state, action) {
      state.GearManifestEnumerators[action.payload.tag].Enumerators = state.GearManifestEnumerators[action.payload.tag].Enumerators.filter((__Enumerator) => __Enumerator.ID !== action.payload.ID);
      toast.update("DeleteEnumerator_", { render: "Enumerator has been deleted!", autoClose: 5000, type: toast.TYPE.SUCCESS, onClose: () => toast.done("DeleteEnumerator_"), isLoading: false });
    },
    EnumeratorsLoaded(state, action) {
      var GearManifestEnumerators = state.GearManifestEnumerators[action.payload.tag];
      if (GearManifestEnumerators == null) {
        GearManifestEnumerators = { Enumerators: [] };
        state.GearManifestEnumerators[action.payload.tag] = GearManifestEnumerators;
      }
      GearManifestEnumerators.Enumerators = action.payload.data;
      state.IsLoading = false;
      GearManifestEnumerators.LastFetch = Date.now();
      GearManifestEnumerators.GearManifestId = action.payload.tag;
    },
  },
});

const { EnumeratorAdded, EnumeratorUpdated, EnumeratorDeleted, EnumeratorsRequested, EnumeratorDeleteRequested, EnumeratorAddRequested, EnumeratorUpdateRequested } = EnumeratorsSlice.actions;
export default EnumeratorsSlice.reducer;
