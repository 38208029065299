import { ObjectBase } from "@arcadia/core.data";
import Component from "./Component";

export default class GearsManifest extends ObjectBase {
  Commit(): void {
    throw new Error("Method not implemented.");
  }
  Revert(): void {
    throw new Error("Method not implemented.");
  }

  Name: string = "";
  IsSystem: boolean = false;
  Publisher: string = "";
  Guid: string = "";
  Namespace: string = "";
  NameID: string = "";
  DescriptionID: string = "";
  VisualID: string = "";
  Purpose: string = "";
  Description: string = "";
  Scope: string = "";
  Performance: string = "";
  Reliability: string = "";
  Availability: string = "";
  Security: string = "";
  Maintainability: string = "";
  Portability: string = "";
  Constraints: string = "";
  PhrasesVersion: string = "";
  ResourcesVersion: string = "";
  IsPhrasesExported: boolean = false;
  Color: string = "";
  HelpLink: string = "";
  ReadMe: string = "";
  SupportLink: string = "";
  SupportPhone: string = "";
  Url: string = "";
  IsResourcesExported: boolean = false;
  AzureDevOpsID: string = "";

  Components: Component[] = [];
}
