import { ColoredScrollbars, StyledTreeItem } from "@arcadia/core.base.ui";
import { ArrowDropDown as ArrowDropDownIcon, ArrowRight as ArrowRightIcon, Category as CategoryIcon, Input as InputIcon } from "@mui/icons-material";
import { TreeView } from "@mui/lab";
import { Grid } from "@mui/material";
import React from "react";
import FieldsGroupProperties from "./FieldsPartials/FieldsGroupProperties";
import FieldView from "./FieldsPartials/FieldView";
import Field from "../../../../../DataServices/Modals/Field";
import { FieldsGroupsProps } from "./FieldsGroupsProps";
import FieldsGroup from "../../../../../DataServices/Modals/FieldsGroup";

function GetSelectedPartial(selectedPartial: string, selectedItemId: string, props: FieldsGroupsProps): JSX.Element {
  if (selectedItemId === "") return <>&lt;&lt;&lt;&lt; Please select an item to display..</>;
  switch (selectedPartial) {
    case "FieldsGroup":
      const _selectedFieldsGroup = props.FieldsGroups.filter((__fieldsGroup) => __fieldsGroup.ID === selectedItemId)[0];
      return <FieldsGroupProperties FieldsGroup={_selectedFieldsGroup} />;
    case "Field":
      let result = <>Oh shit! {selectedItemId}</>;
      props.FieldsGroups.forEach((fieldsGroup) => {
        fieldsGroup.Fields.forEach((field) => {
          if (field.ID === selectedItemId) result = <FieldView FieldData={field} />;
        });
      });

      return result;
    default:
      throw new Error(`Invalid selectedPartial: ${selectedPartial}`);
  }
}

const Fields = (props: FieldsGroupsProps) => {
  const [selectedPartial, setSelectedPartial] = React.useState("");
  const [selectedItemId, setSelectedItemId] = React.useState("");

  function OnItemSelected(event: React.SyntheticEvent, nodeId: string) {
    if (nodeId.startsWith("g_")) setSelectedPartial("FieldsGroup");
    else if (nodeId.startsWith("f_")) setSelectedPartial("Field");
    else throw new Error("Selected TreeNode is not recognized!");

    const _id = nodeId.substring(2);
    setSelectedItemId(_id);
  }

  return (
    <Grid
      justifyContent="stretch"
      container
      spacing={0}
      sx={{
        flex: 1,
        height: "100%",
      }}
    >
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3} sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, backgroundColor: (theme) => theme.palette.background.default }}>
        <ColoredScrollbars>
          <TreeView onNodeSelect={OnItemSelected} defaultExpanded={["3"]} defaultCollapseIcon={<ArrowDropDownIcon />} defaultExpandIcon={<ArrowRightIcon />} defaultEndIcon={<div style={{ width: 24 }} />} sx={{ flexGrow: 1, maxWidth: 400, overflowY: "auto" }}>
            {props.FieldsGroups.map((_fieldsGroup: FieldsGroup) => (
              <StyledTreeItem key={`g_${_fieldsGroup.ID}`} nodeId={`g_${_fieldsGroup.ID}`} color="#1E88E5" labelIcon={CategoryIcon} labelText={_fieldsGroup.Name}>
                {_fieldsGroup.Fields.map((_field: Field) => (
                  <StyledTreeItem key={`f_${_field.ID}`} nodeId={`f_${_field.ID}`} color="#009688" labelIcon={InputIcon} labelText={_field.ColumnName} />
                ))}
              </StyledTreeItem>
            ))}
          </TreeView>
        </ColoredScrollbars>
      </Grid>

      <Grid
        item
        xs={0}
        sm={6}
        md={9}
        lg={9}
        xl={9}
        sx={{
          display: {
            xs: "none",
            md: "initial",
          },
        }}
      >
        {GetSelectedPartial(selectedPartial, selectedItemId, props)}
      </Grid>
    </Grid>
  );
};

export default Fields;
