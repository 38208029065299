import { ColoredScrollbars, StyledTreeItem } from "@arcadia/core.base.ui";
import { ArrowDropDown as ArrowDropDownIcon, ArrowRight as ArrowRightIcon, ViewInAr as ViewInArIcon } from "@mui/icons-material";
import { TreeView } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import React from "react";
import CommandView from "./CommandsDefinitionsPartials/CommandsDefinitionView";
import CommandsDefinition from "../../../DataServices/Modals/CommandsDefinition";
interface CommandsDefinitionsProps {
  CommandsDefinitions: CommandsDefinition[];
}

function GetSelectedPartial(selectedPartial: string, selectedItemId: string, props: CommandsDefinitionsProps): JSX.Element {
  if (selectedItemId === "") return <>&lt;&lt;&lt;&lt; Please select an item to display..</>;
  switch (selectedPartial) {
    case "Command":
      const _commandDefinition = props.CommandsDefinitions.filter((__commandDefinition) => __commandDefinition.ID === selectedItemId);
      if (_commandDefinition.length === 0) return <>Ops, Command Definition not found in props!</>;
      return <CommandView CommandDefinition={_commandDefinition[0]} />;
    default:
      throw new Error("Invalid selectedPartial: " + selectedPartial);
  }
}

const CommandsDefinitions = (props: CommandsDefinitionsProps) => {
  const [selectedPartial, setSelectedPartial] = React.useState("Command");
  const [selectedItemId, setSelectedItemId] = React.useState("");

  function OnItemSelected(event: React.SyntheticEvent, nodeId: string) {
    if (nodeId.startsWith("c_")) setSelectedPartial("Command");
    else throw new Error("Selected TreeNode is not recognized!");

    const _id = nodeId.substring(2);
    setSelectedItemId(_id);
  }

  function GetSubCommands(__commandId: string): JSX.Element[] {
    var _result: JSX.Element[] = [];
    props.CommandsDefinitions.filter((__commandsDefinition: CommandsDefinition) => __commandsDefinition.ParentID === __commandId).forEach((_commandDefinition: CommandsDefinition) => {
      _result.push(
        <StyledTreeItem key={`c_${_commandDefinition.ID}`} nodeId={`c_${_commandDefinition.ID}`} color="#1E88E5" labelIcon={ViewInArIcon} labelText={_commandDefinition.Name}>
          {GetSubCommands(_commandDefinition.ID)}
        </StyledTreeItem>
      );
    });
    return _result;
  }

  return (
    <Grid
      direction="row"
      justifyContent="stretch"
      container
      spacing={0.5}
      sx={{
        borderRadius: 2,
        flex: 1,
        height: "100%",
      }}
    >
      <Grid container item direction="column" xs={12} sm={6} md={3} lg={2} xl={2} sx={{ height: "100%" }}>
        <Grid
          item
          sx={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            backgroundColor: (theme) => theme.palette.background.default,
            display: "flex",
            justifyContent: "center",
            paddingBottom: 2,
            alignItems: "stretch",
            height: "100%",
            padding: 0,
          }}
        >
          <ColoredScrollbars>
            <TreeView onNodeSelect={OnItemSelected} defaultExpanded={["3"]} defaultCollapseIcon={<ArrowDropDownIcon />} defaultExpandIcon={<ArrowRightIcon />} defaultEndIcon={<div style={{ width: 24 }} />} sx={{ flexGrow: 1, maxWidth: 400, overflowY: "auto" }}>
              {props.CommandsDefinitions.filter((__commandsDefinition) => __commandsDefinition.ParentID === null).map((_commandDefinition: CommandsDefinition) => (
                <StyledTreeItem key={`c_${_commandDefinition.ID}`} nodeId={`c_${_commandDefinition.ID}`} color="#1E88E5" labelIcon={ViewInArIcon} labelText={_commandDefinition.Name}>
                  {GetSubCommands(_commandDefinition.ID)}
                </StyledTreeItem>
              ))}
            </TreeView>
          </ColoredScrollbars>
        </Grid>
      </Grid>

      <Grid
        item
        xs={0}
        sm={6}
        md={9}
        lg={10}
        xl={10}
        sx={{
          display: {
            xs: "none",
            md: "initial",
          },
        }}
      >
        <Box sx={{ height: "100%" }}>
          <ColoredScrollbars>{GetSelectedPartial(selectedPartial, selectedItemId, props)}</ColoredScrollbars>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CommandsDefinitions;
