import { Box, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import { ColorPicker } from "@arcadia/core.base.ui";
import SettingsSet from "../../../../DataServices/Modals/SettingsSet";
import { Cancel as CancelIcon, Delete as DeleteIcon, Save as SaveIcon } from "@mui/icons-material";

interface SettingsSetProps {
  SettingsSet: SettingsSet;
}

const SettingsSetView = (props: SettingsSetProps) => {
  return (
    <Box
      sx={{
        minWidth: "350px",
        alignItems: "left",
      }}
    >
      <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.grey[800], borderRadius: 4, paddingLeft: 2, textAlign: "center" }}>
        {props.SettingsSet.Name}
      </Typography>
      <Formik initialValues={props.SettingsSet} onSubmit={(values, { setSubmitting }) => {}}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field component={TextField} autoFocus sx={{ marginTop: 1.5, marginLeft: 1, width: 500 }} name="Name" label="Name" type="text" required variant="filled" id="txtName" />
            <Box>
              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcTitleLabel">Title</InputLabel>
                <Select labelId="slcTitleLabel" id="slcTitle" label="Title" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
                <InputLabel id="slcDescriptionLabel">Description</InputLabel>
                <Select labelId="slcDescriptionLabel" id="slcDescription" label="Description" value="Core">
                  <MenuItem value="Arcadia">Arcadia</MenuItem>
                  <MenuItem value="Core">Core</MenuItem>
                  <MenuItem value="Providers">Providers</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
              <InputLabel id="slcIconLabel">Icon</InputLabel>
              <Select labelId="slcIconLabel" id="slcIcon" label="Icon" value="Core">
                <MenuItem value="Arcadia">Arcadia</MenuItem>
                <MenuItem value="Core">Core</MenuItem>
                <MenuItem value="Providers">Providers</MenuItem>
              </Select>
            </FormControl>

            <InputLabel sx={{ marginTop: 1 }}>
              Color
              <ColorPicker />
            </InputLabel>

            <ButtonGroup style={{ margin: 5 }} variant="contained">
              <Button color="primary" startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button color="secondary" startIcon={<CancelIcon />}>
                Cancel
              </Button>
              <Button color="error" startIcon={<DeleteIcon />}>
                Delete
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SettingsSetView;
