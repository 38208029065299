import { Box, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Cancel as CancelIcon, Delete as DeleteIcon, Save as SaveIcon } from "@mui/icons-material";
import { Checkbox, TextField } from "formik-mui";
import React from "react";
import FieldsGroup from "../../../../../../DataServices/Modals/FieldsGroup";
export interface FieldsGroupProps {
  FieldsGroup: FieldsGroup;
}
const FieldsGroupProperties = ({ FieldsGroup }: FieldsGroupProps) => {
  return (
    <Box
      sx={{
        alignItems: "left",
        marginLeft: 1,
        marginRight: 1,
      }}
    >
      <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.grey[800], borderRadius: 4, paddingLeft: 2, textAlign: "center" }}>
        {FieldsGroup.Name}
      </Typography>

      <Formik initialValues={FieldsGroup} onSubmit={(values, { setSubmitting }) => {}}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field component={TextField} autoFocus margin="normal" name="Name" label="Name" type="text" required variant="filled" id="txtName" />

            <Box>
              <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
                <InputLabel id="slcTitleLabel">Title</InputLabel>
                <Select labelId="slcTitleLabel" id="slcTitle" label="Title" defaultValue="3" style={{ margin: 3 }}>
                  <MenuItem value="1">Arcadia</MenuItem>
                  <MenuItem value="2">Core</MenuItem>
                  <MenuItem value="3">Providers</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
                <InputLabel id="slcDescriptionLabel">Description</InputLabel>
                <Select labelId="slcDescriptionLabel" id="slcDescription" label="Description" defaultValue="3" style={{ margin: 3 }}>
                  <MenuItem value="1">Arcadia</MenuItem>
                  <MenuItem value="2">Core</MenuItem>
                  <MenuItem value="3">Providers</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box>
              <InputLabel sx={{ width: 170, display: "inline-block" }}>
                <Field component={Checkbox} margin="normal" name="DialogGroup" type="checkbox" required variant="filled" id="chkDialogGroup" />
                Dialog Group
              </InputLabel>

              <InputLabel sx={{ width: 170, display: "inline-block" }}>
                <Field component={Checkbox} margin="normal" name="ViewGroup" type="checkbox" required variant="filled" id="chkViewGroup" />
                View Group
              </InputLabel>
            </Box>

            <Field component={TextField} margin="normal" name="ColumnsCount" label="Columns Count" type="text" required variant="filled" id="txtColumns" />
            <br />
            <ButtonGroup style={{ margin: 5 }} variant="contained">
              <Button color="primary" startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button color="secondary" startIcon={<CancelIcon />}>
                Cancel
              </Button>
              <Button color="error" startIcon={<DeleteIcon />}>
                Delete
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default FieldsGroupProperties;
