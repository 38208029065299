import { ListAlt as ListAltIcon } from "@mui/icons-material";
import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import EnumeratorView from "./EnumeratorsPartials/EnumeratorView";
import { ColoredScrollbars, PartialIdProps } from "@arcadia/core.base.ui";
import Enumerator from "../../../DataServices/Modals/Enumerator";
import { useAppDispatch, useAppSelector } from "../../../DataServices/DataApi/DataStore";
import { LoadEnumerators } from "../../../DataServices/DataApi/Slices/EnumeratorsSlice";

const Enumerators = (props: PartialIdProps) => {
  const [selectedEnumerator, setSelectedEnumerator] = React.useState<Enumerator>();

  const _gearManifestsEnumerators = useAppSelector((state) => state.Enumerators);
  const _gearManifestEnumerators = _gearManifestsEnumerators.GearManifestEnumerators[props.Id];
  const _enumerators = _gearManifestEnumerators == null ? [] : _gearManifestEnumerators.Enumerators;

  const dispatch = useAppDispatch();
  function LoadData() {
    if (props.Id === "" || _gearManifestsEnumerators.IsLoading) return;
    dispatch(LoadEnumerators(props.Id));
  }
  React.useEffect(() => {
    LoadData();
  }, [props]);

  return (
    <Grid
      direction="row"
      justifyContent="stretch"
      container
      spacing={0.5}
      sx={{
        borderRadius: 2,
        flex: 1,
        height: "100%",
      }}
    >
      <Grid container item direction="column" xs={12} sm={6} md={3} lg={2} xl={2} sx={{ height: "100%" }}>
        <Grid
          item
          sx={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            backgroundColor: (theme) => theme.palette.background.default,
            display: "flex",
            justifyContent: "center",
            paddingBottom: 2,
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <ColoredScrollbars>
            <List>
              {_enumerators.map((_enumerator: Enumerator) => (
                <ListItem key={_enumerator.ID} color="#fba428" disablePadding>
                  <ListItemButton onClick={() => setSelectedEnumerator(_enumerator)}>
                    <ListItemIcon>
                      <ListAltIcon />
                    </ListItemIcon>
                    <ListItemText primary={_enumerator.Name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </ColoredScrollbars>
        </Grid>
      </Grid>

      <Grid
        item
        xs={0}
        sm={6}
        md={9}
        lg={10}
        xl={10}
        sx={{
          display: {
            xs: "none",
            md: "initial",
          },
        }}
      >
        {selectedEnumerator && <EnumeratorView Enumerator={selectedEnumerator} />}
      </Grid>
    </Grid>
  );
};

export default Enumerators;
