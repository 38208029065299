import { Button, ButtonGroup } from "@mui/material";
import React from "react";
import DataGrid, { Column } from "react-data-grid";
import { PartialIdProps } from "@arcadia/core.base.ui";
import Phrase from "../../../DataServices/Modals/Phrase";

function GetColumns(): readonly Column<Phrase>[] {
  return [
    { key: "Name", name: "Name" },
  ];
}

const rows: Phrase[] = [
];

const Phrases = (props: PartialIdProps) => {
  return (
    <>
      <DataGrid
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        columns={GetColumns()}
        style={{ blockSize: "100%" }}
        rows={rows}
      />
      <ButtonGroup style={{ margin: 5 }} variant="contained">
        <Button color="primary">Save</Button>
        <Button color="error">Cancel</Button>
      </ButtonGroup>
    </>
  );
};

export default Phrases;
