import { Box, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Checkbox, TextField } from "formik-mui";
import React from "react";
import FieldData from "../../../../../../DataServices/Modals/Field";
import { Cancel as CancelIcon, Delete as DeleteIcon, Save as SaveIcon } from "@mui/icons-material";
export interface FieldProps {
  FieldData: FieldData;
}

const FieldProperties = ({ FieldData }: FieldProps) => {
  return (
    <Box
      sx={{
        minWidth: "350px",
        alignItems: "left",
      }}
    >
      <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.info.main, borderRadius: 4, paddingLeft: 2, textAlign: "center" }}>
        Field Properties
      </Typography>
      <Formik initialValues={FieldData} onSubmit={(values, { setSubmitting }) => {}}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.background.default, padding: 1, marginTop: 3 }}>
              Basic
            </Typography>
            <Field component={TextField} autoFocus sx={{ marginTop: 1.5, width: 250 }} name="ColumnName" label="ColumnName" type="text" required variant="filled" id="txtColumnName" />
            <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
              <InputLabel id="slcDBTypeLabel">DB Type</InputLabel>
              <Select labelId="slcDBTypeLabel" id="slcDBType" label="DBType" defaultValue="3" style={{ margin: 3 }}>
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>

            <Box>
              <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
                <InputLabel id="slcNameLabel">Name</InputLabel>
                <Select labelId="slcNameLabel" id="slcName" label="Name" defaultValue="3" style={{ margin: 3 }}>
                  <MenuItem value="1">Arcadia</MenuItem>
                  <MenuItem value="2">Core</MenuItem>
                  <MenuItem value="3">Providers</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
                <InputLabel id="slcDescriptionLabel">Description</InputLabel>
                <Select labelId="slcDescriptionLabel" id="slcDescription" label="Description" defaultValue="3" style={{ margin: 3 }}>
                  <MenuItem value="1">Arcadia</MenuItem>
                  <MenuItem value="2">Core</MenuItem>
                  <MenuItem value="3">Providers</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Field component={TextField} sx={{ margin: 1, width: 250 }} name="Length" label="Length" type="text" required variant="filled" id="txtLength" />
            <Field component={TextField} sx={{ margin: 1, width: 250 }} name="Level" label="Level" type="text" required variant="filled" id="txtLevel" />
            <Field component={TextField} sx={{ margin: 1, width: 250 }} name="Weight" label="Weight" type="text" required variant="filled" id="txtWeight" />
            <Field component={TextField} sx={{ margin: 1, width: 250 }} name="FieldOrder" label="Order" type="text" required variant="filled" id="txtFieldOrder" />
            <Field component={TextField} sx={{ margin: 1, width: 250 }} name="RowSpan" label="Row Span" type="text" required variant="filled" id="txtRowSpan" />
            <Field component={TextField} sx={{ margin: 1, width: 250 }} name="ColumnSpan" label="Column Span" type="text" required variant="filled" id="txtColumnSpan" />
            <Field component={TextField} sx={{ margin: 1, width: 250 }} name="DefaultValue" label="Default Value" type="text" required variant="filled" id="txtDefaultValue" />

            <br />
            <InputLabel sx={{ width: 150, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="IsPrimaryKey" type="checkbox" required variant="filled" id="chkIsPrimaryKey" />
              Is Primary Key
            </InputLabel>
            <InputLabel sx={{ width: 120, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="IsNullable" type="checkbox" required variant="filled" id="chkIsNullable" />
              Is Nullable
            </InputLabel>
            <InputLabel sx={{ width: 130, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="Displayable" type="checkbox" required variant="filled" id="chkDisplayable" />
              Displayable
            </InputLabel>
            <InputLabel sx={{ width: 170, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="DefaultDisplayed" type="checkbox" required variant="filled" id="chkDefaultDisplayed" />
              Default Displayed
            </InputLabel>
            <InputLabel sx={{ width: 190, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="DisplayableMinimal" type="checkbox" required variant="filled" id="chkDisplayableMinimal" />
              Displayable Minimal
            </InputLabel>
            <InputLabel sx={{ width: 190, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="ControlledByUser" type="checkbox" required variant="filled" id="chkControlledByUser" />
              Controlled By User
            </InputLabel>
            <InputLabel sx={{ width: 120, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="MetaField" type="checkbox" required variant="filled" id="chkMetaField" />
              Meta Field
            </InputLabel>
            <InputLabel sx={{ width: 170, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="ShowAsGroup" type="checkbox" required variant="filled" id="chkShowAsGroup" />
              Dialog Group
            </InputLabel>

            <Box>
              <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
                <InputLabel id="slcUIElementIDLabel">UI Element</InputLabel>
                <Select labelId="slcUIElementIDLabel" id="slcUIElementID" label="UIElementID" defaultValue="3" style={{ margin: 3 }}>
                  <MenuItem value="1">Arcadia</MenuItem>
                  <MenuItem value="2">Core</MenuItem>
                  <MenuItem value="3">Providers</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
                <InputLabel id="slcDisplayAsLabel">Display As</InputLabel>
                <Select labelId="slcDisplayAsLabel" id="slcDisplayAs" label="DisplayAs" defaultValue="3" style={{ margin: 3 }}>
                  <MenuItem value="1">Arcadia</MenuItem>
                  <MenuItem value="2">Core</MenuItem>
                  <MenuItem value="3">Providers</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.background.default, padding: 1, marginTop: 3 }}>
              Relation
            </Typography>
            <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
              <InputLabel id="slcRelationLabel">Relation</InputLabel>
              <Select labelId="slcRelationLabel" id="slcRelation" label="Relation" defaultValue="3" style={{ margin: 3 }}>
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
              <InputLabel id="slcRelationIDLabel">Relation Item</InputLabel>
              <Select labelId="slcRelationIDLabel" id="slcRelationID" label="RelationID" defaultValue="3" style={{ margin: 3 }}>
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.background.default, padding: 1, marginTop: 3 }}>
              Numbering
            </Typography>
            <InputLabel>
              <Field component={Checkbox} margin="normal" name="IsNumber" type="checkbox" required variant="filled" id="chkIsNumber" />
              IsNumber
            </InputLabel>
            <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
              <InputLabel id="slcLastNumberSettingDefinitionIDLabel">Last Number</InputLabel>
              <Select labelId="slcLastNumberSettingDefinitionIDLabel" id="slcLastNumberSettingDefinitionID" label="LastNumberSettingDefinitionID" defaultValue="3" style={{ margin: 3 }}>
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
              <InputLabel id="slcNumberingMethodSettingDefinitionIDLabel">Numbering Method</InputLabel>
              <Select labelId="slcNumberingMethodSettingDefinitionIDLabel" id="slcNumberingMethodSettingDefinitionID" label="NumberingMethodSettingDefinitionID" defaultValue="3" style={{ margin: 3 }}>
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.background.default, padding: 1, marginTop: 3 }}>
              Search
            </Typography>
            <InputLabel sx={{ width: 190, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="SimpleSearch" type="checkbox" required variant="filled" id="chkSimpleSearch" />
              SimpleSearch
            </InputLabel>
            <InputLabel sx={{ width: 190, display: "inline-block" }}>
              <Field component={Checkbox} margin="normal" name="AdvancedSearch" type="checkbox" required variant="filled" id="chkAdvancedSearch" />
              AdvancedSearch
            </InputLabel>

            <br />
            <FormControl variant="filled" sx={{ marginTop: 1, width: 250 }}>
              <InputLabel id="slcSearchTypeLabel">Search Type</InputLabel>
              <Select labelId="slcSearchTypeLabel" id="slcSearchType" label="SearchType" defaultValue="3" style={{ margin: 3 }}>
                <MenuItem value="1">Arcadia</MenuItem>
                <MenuItem value="2">Core</MenuItem>
                <MenuItem value="3">Providers</MenuItem>
              </Select>
            </FormControl>

            <br />
            <ButtonGroup style={{ margin: 5 }} variant="contained">
              <Button color="primary" startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button color="secondary" startIcon={<CancelIcon />}>
                Cancel
              </Button>
              <Button color="error" startIcon={<DeleteIcon />}>
                Delete
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default FieldProperties;
