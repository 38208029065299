import { ColoredScrollbars, PartialIdProps } from "@arcadia/core.base.ui";
import { Bento as BentoIcon } from "@mui/icons-material";
import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import Backdrop from "../../../Backdrop";
import { useAppDispatch, useAppSelector } from "../../../DataServices/DataApi/DataStore";
import { LoadDataUnits } from "../../../DataServices/DataApi/Slices/DataUnitsSlice";
import DataUnit from "../../../DataServices/Modals/DataUnit";
import DataUnitView from "./ComponentsPartials/DataUnitView";

const DataUnits = (props: PartialIdProps) => {
  const [selectedDataUnit, setSelectedDataUnit] = React.useState<DataUnit>();

  const _componentsDataUnits = useAppSelector((state) => state.DataUnits);
  const _componentDataUnits = _componentsDataUnits.ComponentDataUnits[props.Id];
  const _dataUnits = _componentDataUnits == null ? [] : _componentDataUnits.DataUnits;

  const dispatch = useAppDispatch();
  function LoadData() {
    if (props.Id === "" || _componentsDataUnits.IsLoading) return;
    dispatch(LoadDataUnits(props.Id));
  }
  React.useEffect(() => {
    LoadData();
  }, [props]);

  return (
    <>
      <Backdrop open={_componentsDataUnits.IsLoading} />
      <Grid
        direction="row"
        justifyContent="stretch"
        container
        spacing={0.5}
        sx={{
          borderRadius: 2,
          flex: 1,
          height: "100%",
        }}
      >
        <Grid container item direction="column" xs={12} sm={6} md={3} lg={2} xl={2.2} sx={{ height: "100%", }}>
          <Grid
            item
            sx={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              backgroundColor: (theme) => theme.palette.background.default,
              display: "flex",
              justifyContent: "center",
              paddingBottom: 2,
              alignItems: "stretch",
              height: "100%",
            }}
          >
            <ColoredScrollbars>
              <List>
                {_dataUnits.map((_dataUnit: DataUnit) => (
                  <ListItem key={_dataUnit.ID} color="#fba428" disablePadding>
                    <ListItemButton onClick={() => setSelectedDataUnit(_dataUnit)}>
                      <ListItemIcon>
                        <BentoIcon />
                      </ListItemIcon>
                      <ListItemText primary={_dataUnit.Name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </ColoredScrollbars>
          </Grid>
        </Grid>

        <Grid
          item
          xs={0}
          sm={6}
          md={9}
          lg={10}
          xl={9.8}
          sx={{
            display: {
              xs: "none",
              md: "initial",
            },
          }}
        >
          {selectedDataUnit && <DataUnitView DataUnit={selectedDataUnit} />}
        </Grid>
      </Grid>
    </>
  );
};

export default DataUnits;
