import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import DataUnit from "../../Modals/DataUnit";
import { ApiCallBegan } from "../Api/ApiActions";
import { AppDispatch, RootState } from "../DataStore";

interface ComponentDataUnits {
  DataUnits: DataUnit[];
  LastFetch?: number | null;
  ComponentId?: string | null;
}
interface InitialState {
  ComponentDataUnits: { [id: string]: ComponentDataUnits };
  IsLoading: boolean;
}
export const LoadDataUnits = (ID: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const ComponentDataUnits = getState().DataUnits.ComponentDataUnits[ID];

  if (ComponentDataUnits != null) {
    const diffInMinutss = moment().diff(moment(ComponentDataUnits.LastFetch), "minutes");
    if (diffInMinutss < 10 && ComponentDataUnits.ComponentId === ID) return;
  }

  dispatch(
    ApiCallBegan({
      url: `CodeGeneration/DataUnits/${ID}`,
      onSuccess: DataUnitsSlice.actions.DataUnitsLoaded.type,
      onStart: DataUnitsRequested.type,
      tag: ID,
    })
  );
};

export const AddDataUnit = (DataUnit: DataUnit) => async (dispatch: AppDispatch, getState: () => RootState) =>
  dispatch(
    ApiCallBegan({
      url: "CodeGeneration/DataUnits",
      method: "post",
      data: DataUnit,
      onSuccess: DataUnitAdded.type,
      onStart: DataUnitAddRequested.type,
    })
  );

export const DeleteDataUnit = (ID: string) => async (dispatch: AppDispatch, getState: () => RootState) =>
  dispatch(
    ApiCallBegan({
      url: `CodeGeneration/DataUnits/${ID}`,
      method: "delete",
      onSuccess: DataUnitDeleted.type,
      onStart: DataUnitDeleteRequested.type,
    })
  );

export const UpdateDataUnit = (DataUnit: DataUnit) => async (dispatch: AppDispatch, getState: () => RootState) =>
  dispatch(
    ApiCallBegan({
      url: "CodeGeneration/DataUnits",
      method: "patch",
      data: DataUnit,
      onSuccess: DataUnitUpdated.type,
      onStart: DataUnitUpdateRequested.type,
    })
  );

export async function GetDataUnit(ID: string): Promise<DataUnit | null> {
  const _apiPort = process.env.REACT_APP_API_PORT;

  const response = await axios.request({
    baseURL: `https://botas.sa:${_apiPort}`,
    url: "CodeGeneration/DataUnits/Get/" + ID,
    method: "get",
  });
  return response.data;
}

const DataUnitsSlice = createSlice({
  name: "DataUnits",
  initialState: {
    ComponentDataUnits: {},
    IsLoading: false,
  } as InitialState,
  reducers: {
    DataUnitsRequested: (DataUnits, action) => {
      DataUnits.IsLoading = true;
    },
    DataUnitDeleteRequested: (state, action) => {
      toast.loading("Deleting dataunit please wait...", { autoClose: false, toastId: "DeleteDataUnit_", type: toast.TYPE.INFO });
    },
    DataUnitAddRequested: (state, action) => {},
    DataUnitUpdateRequested: (state, action) => {},
    DataUnitAdded(state, action) {
      // state.DataUnits.push(action.payload);
    },
    DataUnitUpdated(state, action) {
      // state.DataUnits.push(action.payload);
    },
    DataUnitDeleted(state, action) {
      state.ComponentDataUnits[action.payload.tag].DataUnits = state.ComponentDataUnits[action.payload.tag].DataUnits.filter((__dataUnit) => __dataUnit.ID !== action.payload.ID);
      toast.update("DeleteDataUnit_", { render: "DataUnit has been deleted!", autoClose: 5000, type: toast.TYPE.SUCCESS, onClose: () => toast.done("DeleteDataUnit_"), isLoading: false });
    },
    DataUnitsLoaded(state, action) {
      var ComponentDataUnits = state.ComponentDataUnits[action.payload.tag];
      if (ComponentDataUnits == null) {
        ComponentDataUnits = { DataUnits: [] };
        state.ComponentDataUnits[action.payload.tag] = ComponentDataUnits;
      }
      ComponentDataUnits.DataUnits = action.payload.data;
      state.IsLoading = false;
      ComponentDataUnits.LastFetch = Date.now();
      ComponentDataUnits.ComponentId = action.payload.tag;
    },
  },
});

const { DataUnitAdded, DataUnitUpdated, DataUnitDeleted, DataUnitsRequested, DataUnitDeleteRequested, DataUnitAddRequested, DataUnitUpdateRequested } = DataUnitsSlice.actions;
export default DataUnitsSlice.reducer;
