import { Button, ButtonGroup } from "@mui/material";
import React from "react";
import DataGrid, { Column, SelectCellFormatter } from "react-data-grid";
import Field from "../../../../../DataServices/Modals/Field";
import FieldsGroup from "../../../../../DataServices/Modals/FieldsGroup";
import { FieldsGroupsProps } from "./FieldsGroupsProps";

function GetColumns(): readonly Column<Field>[] {
  return [
    { key: "ColumnName", name: "ColumnName" },
    {
      key: "SimpleSearch",
      name: "Simple Search",
      width: 80,
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.SimpleSearch}
            onChange={() => {
              row.SimpleSearch = !row.SimpleSearch;
              onRowChange(row);
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },
    {
      key: "AdvancedSearch",
      name: "Advanced Search",
      width: 80,
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.AdvancedSearch}
            onChange={() => {
              row.AdvancedSearch = !row.AdvancedSearch;
              onRowChange(row);
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },
    {
      key: "SearchType",
      name: "Search Type",
      width: 80,
    },
    {
      key: "Displayable",
      name: "Displayable",
      width: 80,
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.Displayable}
            onChange={() => {
              row.Displayable = !row.Displayable;
              onRowChange(row);
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },
    {
      key: "DefaultDisplayed",
      name: "Default Displayed",
      width: 80,
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.DefaultDisplayed}
            onChange={() => {
              row.DefaultDisplayed = !row.DefaultDisplayed;
              onRowChange(row);
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },
    {
      key: "Weight",
      name: "Weight",
      width: 80,
    },
    {
      key: "ControlledByUser",
      name: "Controlled By User",
      width: 80,
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.ControlledByUser}
            onChange={() => {
              row.ControlledByUser = !row.ControlledByUser;
              onRowChange(row);
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },
    {
      key: "FieldLevel",
      name: "Level",
      width: 80,
    },
    {
      key: "DisplayableMinimal",
      name: "Displayable Minimal",
      width: 80,
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.DisplayableMinimal}
            onChange={() => {
              row.DisplayableMinimal = !row.DisplayableMinimal;
              onRowChange(row);
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },
    {
      key: "DisplayAs",
      name: "Display As",
      width: 80,
    },
    {
      key: "MetaField",
      name: "Meta Field",
      width: 80,
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.MetaField}
            onChange={() => {
              row.MetaField = !row.MetaField;
              onRowChange(row);
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },
    {
      key: "ShowAsGroup",
      name: "Show As Group",
      width: 80,
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.ShowAsGroup}
            onChange={() => {
              row.ShowAsGroup = !row.ShowAsGroup;
              onRowChange(row);
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },
  ];
}

const FieldsEditor = (props: FieldsGroupsProps) => {
  var rows: Field[] = [];
  props.FieldsGroups.forEach((_fieldsGroup: FieldsGroup) => {
    _fieldsGroup.Fields.forEach((_field: Field) => {
      rows.push(_field);
    });
  });
  return (
    <>
      <DataGrid
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        columns={GetColumns()}
        style={{ blockSize: "100%" }}
        rows={rows}
      />
      <ButtonGroup style={{ margin: 5 }} variant="contained">
        <Button color="primary">Save</Button>
        <Button color="error">Cancel</Button>
      </ButtonGroup>
    </>
  );
};

export default FieldsEditor;
