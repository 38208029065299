import { Box, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Checkbox, TextField } from "formik-mui";
import DataGrid, { Column } from "react-data-grid";
import React from "react";
import { Cancel as CancelIcon, Delete as DeleteIcon, Save as SaveIcon } from "@mui/icons-material";
import EnumeratorItem from "../../../../DataServices/Modals/EnumeratorItem";
import Enumerator from "../../../../DataServices/Modals/Enumerator";

export interface EnumeratorProps {
  Enumerator: Enumerator;
}

const EnumeratorView = ({ Enumerator }: EnumeratorProps) => {
  function GetColumns(): readonly Column<EnumeratorItem>[] {
    return [
      { key: "Item", name: "Item", width: 180 },
      { key: "Value", name: "Value", width: 80 },
      { key: "EnumeratorID", name: "EnumeratorID", width: 100 },
      { key: "PhraseID", name: "PhraseID", width: 80 },
      { key: "VisualID", name: "VisualID", width: 80 },
      { key: "Color", name: "Color", width: 80 },
      { key: "ItemOrder", name: "ItemOrder", width: 80 },
      { key: "DescriptionPhraseID", name: "DescriptionPhraseID", width: 80 },
    ];
  }

  const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<string>>(() => new Set());
  function OnRowClick(row: EnumeratorItem, column: Column<EnumeratorItem, unknown>) {
    setSelectedRows(new Set<string>([row.ID]));
  }
  function rowKeyGetter(row: EnumeratorItem) {
    return row.ID;
  }

  return (
    <Box
      sx={{
        minWidth: "350px",
        alignItems: "left",
      }}
    >
      <Typography variant="h6" sx={{ backgroundColor: (theme) => theme.palette.grey[800], borderRadius: 4, paddingLeft: 2, textAlign: "center" }}>
        {Enumerator.Name}
      </Typography>
      <Formik initialValues={Enumerator} onSubmit={(values, { setSubmitting }) => {}}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field component={TextField} autoFocus margin="normal" name="Name" label="Name" type="text" required variant="filled" id="txtName" />
            <br />
            <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
              <InputLabel id="slcTitleLabel">Title</InputLabel>
              <Select labelId="slcTitleLabel" id="slcTitle" label="Title" value="Core">
                <MenuItem value="Arcadia">Arcadia</MenuItem>
                <MenuItem value="Core">Core</MenuItem>
                <MenuItem value="Providers">Providers</MenuItem>
              </Select>
            </FormControl>
            <br />
            <FormControl variant="filled" sx={{ margin: 1, width: 200 }}>
              <InputLabel id="slcGearManifestLabel">Gear Manifest</InputLabel>
              <Select labelId="slcGearManifestLabel" id="slcGearManifest" label="GearManifest" value="Core">
                <MenuItem value="Arcadia">Arcadia</MenuItem>
                <MenuItem value="Core">Core</MenuItem>
                <MenuItem value="Providers">Providers</MenuItem>
              </Select>
            </FormControl>

            <InputLabel>
              <Field component={Checkbox} margin="normal" name="Flags" type="checkbox" required variant="filled" id="chkFlags" />
              Flags
            </InputLabel>

            <DataGrid
              rowKeyGetter={rowKeyGetter}
              selectedRows={selectedRows}
              onSelectedRowsChange={setSelectedRows}
              onRowClick={OnRowClick}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
              columns={GetColumns()}
              style={{ blockSize: "100%" }}
              rows={Enumerator.Items}
            />

            <ButtonGroup style={{ margin: 5 }} variant="contained">
              <Button color="primary" startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button color="secondary" startIcon={<CancelIcon />}>
                Cancel
              </Button>
              <Button color="error" startIcon={<DeleteIcon />}>
                Delete
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EnumeratorView;
