import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider, useAuth } from "react-oidc-context";
import reportWebVitals from "./reportWebVitals";
import { App, ApplicationSettingsContextProvider, FormAction, NavigationItem, NavigationItemGroup } from "@arcadia/core.base.ui";
import { Provider } from "react-redux";
import { OidcSettings } from "./authentication/AuthenticationSettings";
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import Login from "./Pages/Login";
import GearsManifests from "./Pages/GearsManifests/GearsManifests";
import GearsManifestForm from "./Pages/GearsManifests/GearsManifestForm";
import ValidationDefinitions from "./Pages/ValidationDefinitions/ValidationDefinitions";
import ValidationPresets from "./Pages/ValidationPresets/ValidationPresets";
import CodeTemplateOperations from "./Pages/CodeTemplateOperations/CodeTemplateOperations";
import CodeTemplates from "./Pages/CodeTemplates/CodeTemplates";
import UIElements from "./Pages/UIElements/UIElements";
import DataStore from "./DataServices/DataApi/DataStore";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-data-grid/lib/styles.css";
import ListsDefinitions from "./Pages/ListsDefinitions/ListsDefinitions";

const gearsManifestsNavItems: NavigationItem = new NavigationItem(
  "GearsManifests",
  "Arcadia.GearsManifests",
  "Arcadia.GearsManifests",
  false,
  "/Arcadia/GearsManifests",
  <GearsManifests />,
  [
    new NavigationItem("AddUser", "Core.AddUser", "Core.AddUser", false, "/Arcadia/GearsManifests/Add", <GearsManifestForm Action={FormAction.Add} Id={""} />, [], true),
    new NavigationItem("EditUser", "Core.EditUser", "Core.EditUser", false, "/Arcadia/GearsManifests/Edit", <GearsManifestForm Action={FormAction.Edit} Id={""} />, [], true),
    new NavigationItem("DeleteUser", "Core.DeleteUser", "Core.DeleteUser", false, "/Arcadia/GearsManifests/Delete", <GearsManifestForm Action={FormAction.Delete} Id={""} />, [], true),
  ],
  true
);

const listsDefinitionsNavItems: NavigationItem = new NavigationItem("ListsDefinitions", "Arcadia.ListsDefinitions", "Arcadia.ListsDefinitions", false, "/Arcadia/ListsDefinitions", <ListsDefinitions />, [], true);
const uiElementsNavItems: NavigationItem = new NavigationItem("UIElements", "Arcadia.UIElements", "Arcadia.UIElements", false, "/Arcadia/UIElements", <UIElements />, [], true);
const codeTemplatesNavItems: NavigationItem = new NavigationItem("CodeTemplates", "Arcadia.CodeTemplates", "Arcadia.CodeTemplates", false, "/Arcadia/CodeTemplates", <CodeTemplates />, [], true);
const codeTemplateOperationsNavItems: NavigationItem = new NavigationItem("CodeTemplateOperations", "Arcadia.CodeTemplateOperations", "Arcadia.CodeTemplateOperations", false, "/Arcadia/CodeTemplateOperations", <CodeTemplateOperations />, [], true);
const validationPresetsNavItems: NavigationItem = new NavigationItem("ValidationPresets", "Arcadia.ValidationPresets", "Arcadia.ValidationPresets", false, "/Arcadia/ValidationPresets", <ValidationPresets />, [], true);
const validationDefinitionNavItems: NavigationItem = new NavigationItem("ValidationDefinitions", "Arcadia.ValidationDefinitions", "Arcadia.ValidationDefinitions", false, "/Arcadia/ValidationDefinitions", <ValidationDefinitions />, [], true);

const navItems: NavigationItemGroup[] = [
  new NavigationItemGroup("Arcadia", "Core.Arcadia", "Core.Arcadia", "#FB8C00", [gearsManifestsNavItems, listsDefinitionsNavItems, uiElementsNavItems, codeTemplatesNavItems, codeTemplateOperationsNavItems, validationPresetsNavItems, validationDefinitionNavItems]),
  new NavigationItemGroup("Identities", "Core.Identities", "Core.Identities", "#388E3C", []),
  new NavigationItemGroup("Profiles", "Core.Profiles", "Core.Profiles", "#1976D2", []),
];

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
export const Renew = () => {
  const auth = useAuth();
  auth.startSilentRenew();
  return <></>;
};

root.render(
  <React.StrictMode>
    <Provider store={DataStore}>
      <AuthProvider client_id={OidcSettings.client_id} authority={OidcSettings.authority} redirect_uri={OidcSettings.redirect_uri} client_secret={OidcSettings.client_secret} scope={OidcSettings.scope} response_type={OidcSettings.response_type}>
        <ApplicationSettingsContextProvider>
          <Renew />
          <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
          <App HomePage={<Home />} LoginPage={<Login />} NotFoundPage={<NotFound />} NavigationGroups={navItems} />
        </ApplicationSettingsContextProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
